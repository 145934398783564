import React, { useEffect, useRef, useState } from "react";
import BufferLoader from "./BufferLoader";

const Wave = ({ track }) => {

  const canvasRef = useRef(null);
  const containerRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [canvasWidth, setCanvasWidth] = useState(500); // Valore predefinito
  const [isBufferLoaded, setIsBufferLoaded] = useState(false); // NEW STATE

  
  useEffect(() => {
    const updateCanvasSize = () => {
      if (containerRef.current) {
        setCanvasWidth(containerRef.current.clientWidth);
      }
    };

    window.addEventListener("resize", updateCanvasSize);
    updateCanvasSize(); // Imposta la larghezza iniziale

    return () => window.removeEventListener("resize", updateCanvasSize);
  }, []);


  useEffect(() => {
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    console.log("Track URL in Wave:", track.url); // ADD THIS LINE

    const drawSample = (buffer) => {
      const canvas = canvasRef.current;
      if (!canvas) return;
      if (!buffer) {
          console.error("Wave.js: drawSample received explicitly NULL buffer (from BufferLoader error)");
          setLoading(false);
          return;
      }
      const ctx = canvas.getContext("2d");
      const width = canvas.width;
      const height = canvas.height;
      const peaks = getPeaks(buffer, width);
      ctx.clearRect(0, 0, width, height);
      ctx.fillStyle = "maroon";
      peaks.forEach((peak, x) => {
          ctx.fillRect(x, height / 2 - peak, 1, peak * 2);
      });
      setLoading(false);
      setIsBufferLoaded(true); // <----------------- SET STATE TO TRUE when buffer is loaded!
  };

  const getPeaks = (buffer, width) => {
      const channelData = buffer.getChannelData(0);
      const blockSize = Math.floor(channelData.length / width);
      const peaks = [];
      for (let i = 0; i < width; i++) {
          const blockStart = i * blockSize;
          const blockEnd = blockStart + blockSize;
          let max = 0;
          for (let j = blockStart; j < blockEnd; j++) {
              max = Math.max(max, Math.abs(channelData[j]));
          }
          peaks.push(max * 100);
      }
      return peaks;
  };

  const bufferLoader = new BufferLoader(
      audioContext,
      [track.url],
      drawSample
  );
  bufferLoader.load();
}, [track, canvasWidth]);

  return (
    <div ref={containerRef} style={{ position: "relative", width: "100%", height: "50px" }}>
      <canvas
        ref={canvasRef}
        width={canvasWidth}
        height={50}
        style={{ border: "1px solid #ddd", display: "block", width: "100%" }}
      />



      {loading && (
        <p
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            margin: 0,
            color: "maroon",
            fontWeight: "bold",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            padding: "5px 10px",
            borderRadius: "5px",
          }}
        >
          Loading waveform...
        </p>
      )}
    </div>
  );
};

export default Wave;