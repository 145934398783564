import React, { useState } from 'react';
import { Card, CardContent, Typography, Grid, Rating, Box, Button, LinearProgress, Tooltip, Icon } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import FeedbackModal from './FeedbackModal'; // Importa il modal

function SuggestedExercises({ exercises, onSongSelect }) {
    const [openFeedbackModal, setOpenFeedbackModal] = useState(false);
    const [exerciseForFeedback, setExerciseForFeedback] = useState(null);

    const handleOpenFeedbackModal = (exerciseId) => {
        setExerciseForFeedback(exerciseId);
        setOpenFeedbackModal(true);
    };

    const handleCloseFeedbackModal = () => {
        setOpenFeedbackModal(false);
        setExerciseForFeedback(null);
    };

   

    if (!exercises || exercises.length === 0) {
        return (
            <Typography variant="h6" gutterBottom>
                No exercises suggested at this time.
            </Typography>
        );
    }

    return (
        <Box sx={{ flexGrow: 1, marginTop: 2 }}>
            <Typography variant="h6" gutterBottom>
                Suggested Exercises
            </Typography>
            <Grid container spacing={2}>
                {exercises.map((exercise) => (
                    <Grid item xs={12} sm={6} md={4} key={exercise.id}>
                        <Card sx={{ backgroundColor: '#f3f6f9' }}>
                            <CardContent>
                                <Typography variant="h5" component="div">
                                    {exercise.songName}
                                </Typography>
                                <Typography>
                                    Score: {exercise.score.toFixed(2)}
                                </Typography>

                                {/* Visualizzazione Avanzamento */}
                                <Box sx={{ mt: 2, mb: 1, display: 'flex', alignItems: 'center' }}>
                                    <Typography variant="body2" sx={{ mr: 1 }}>
                                        Progress:
                                    </Typography>
                                    <LinearProgress
                                        variant="determinate"
                                        value={exercise.completion_percentage || 0}
                                        sx={{ width: '100px', height: '8px', mr: 1 }}
                                    />
                                    <Typography variant="body2">
                                        {(exercise.completion_percentage || 0).toFixed(0)}%
                                    </Typography>
                                </Box>

                              
                                {exercise.songName && (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => onSongSelect(exercise.songName)}
                                        sx={{ marginTop: 2 }}
                                    >
                                        Load Song
                                    </Button>
                                )}
                                {/* Pulsante per aprire il modal */}
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={() => handleOpenFeedbackModal(exercise.exercise_id)}
                                    sx={{ marginTop: 2 }}
                                >
                                    Submit Feedback
                                </Button>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>

            {/* Renderizza il modal */}
            <FeedbackModal
                open={openFeedbackModal}
                onClose={handleCloseFeedbackModal}
                exerciseId={exerciseForFeedback}
            />
        </Box>
    );
}

export default SuggestedExercises;