import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const TrackManager = ({ folderName, open, onClose }) => {
  const [tracks, setTracks] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  useEffect(() => {
    if (open) {
      fetchTracks(folderName);
      fetchUploadedFiles();
    }
  }, [open, folderName]);

  const fetchTracks = async (folderName) => {
    try {
      const response = await axios.get(`https://www.jazzinator.com/api/file-manager/list/${folderName}`);
      setTracks(response.data);
    } catch (error) {
      console.error('Errore nel recupero delle tracce:', error);
    }
  };

  const fetchUploadedFiles = async () => {
    try {
      const response = await axios.get('https://www.jazzinator.com/api/file-manager/files');
      setUploadedFiles(response.data);
    } catch (error) {
      console.error('Errore nel recupero dei file caricati:', error);
    }
  };

  const handleDeleteTrack = async (track) => {
    try {
      await axios.delete(`https://www.jazzinator.com/api/track`, { data: { fileName: track, folderName } });
      fetchTracks(folderName);
    } catch (error) {
      console.error('Errore durante la cancellazione della traccia:', error);
    }
  };

  const handleAddTrack = async (file) => {
    const trackName = file.trackName;
    const trackNumber = file.trackNumber;

    if (!isTrackValid(file)) return;

    try {
      await axios.post('https://www.jazzinator.com/api/file-manager/upload-metadata', {
        folderName,
        trackName,
        trackNumber,
        uploadedFileName: file.fullName
      });
      fetchTracks(folderName);

      // Rimuove il file spostato dall'elenco senza ricaricare tutto
      setUploadedFiles((prev) => prev.filter((f) => f.fullName !== file.fullName));
    } catch (error) {
      console.error('Errore durante lo spostamento del file:', error);
    }
  };

  const handleInputChange = (index, field, value) => {
    setUploadedFiles((prev) => {
      const updatedFiles = [...prev];
      updatedFiles[index] = { ...updatedFiles[index], [field]: value };
      return updatedFiles;
    });
  };

  // Funzione che verifica se una traccia ha numero e nome validi
  const isTrackValid = (file) => {
    return file.trackNumber !== undefined && file.trackNumber !== '' &&
           !isNaN(file.trackNumber) && // Controllo che sia un numero valido
           file.trackName !== undefined && file.trackName.trim() !== '';
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Tracce di {folderName}</DialogTitle>
      <DialogContent>
        <Typography variant="h6">Tracce Esistenti</Typography>
        <List>
          {tracks.map((track, index) => (
            <ListItem key={index}>
              <ListItemText primary={track} />
              <ListItemSecondaryAction>
                <IconButton edge="end" onClick={() => handleDeleteTrack(track)}>
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>

        <Typography variant="h6">Tracce Caricate (Uploads)</Typography>
        <List>
          {uploadedFiles.map((file, index) => (
            <ListItem key={index}>
              <ListItemText primary={file.fullName} />
              <TextField
                label="Numero Traccia"
                value={file.trackNumber || ''}
                onChange={(e) => handleInputChange(index, 'trackNumber', e.target.value)}
                style={{ marginRight: '10px' }}
                error={file.trackNumber !== undefined && isNaN(file.trackNumber)}
                helperText={file.trackNumber !== undefined && isNaN(file.trackNumber) ? "Inserire un numero valido" : ""}
              />
              <TextField
                label="Nome Traccia"
                value={file.trackName || ''}
                onChange={(e) => handleInputChange(index, 'trackName', e.target.value)}
                style={{ marginRight: '10px' }}
              />
              <Button 
                variant="contained" 
                color="primary" 
                onClick={() => handleAddTrack(file)}
                disabled={!isTrackValid(file)}
              >
                Aggiungi al Brano
              </Button>
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">Chiudi</Button>
      </DialogActions>
    </Dialog>
  );
};

export default TrackManager;
