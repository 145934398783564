import React, { useState, useEffect, useRef } from 'react';
import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

function PDFViewerDirect({ folderName }) {
    const canvasRef = useRef(null);
    const nextButtonRef = useRef(null);
    const [pdfDoc, setPdfDoc] = useState(null);
    const [pageNum, setPageNum] = useState(1);
    const [numPages, setNumPages] = useState(null);
    const [pageRendering, setPageRendering] = useState(false);
    const [pageNumPending, setPageNumPending] = useState(null);
    const scale = 1.5;
    const isInitialLoad = useRef(true);

    useEffect(() => {
        if (!folderName) return;

        // Reset dello stato quando cambia il brano
        setPageNum(1);
        setPageRendering(false);
        setPageNumPending(null);

        GlobalWorkerOptions.workerSrc =
            'https://cdn.jsdelivr.net/npm/pdfjs-dist@2.16.105/build/pdf.worker.min.js';

        const loadAndRenderPDF = async () => {
            try {
                const response = await fetch(`https://www.jazzinator.com/api/file-manager/upload-pdf/${folderName}`);
                if (!response.ok) {
                    throw new Error(`Failed to fetch PDF: ${response.status}`);
                }
                const blob = await response.blob();
                const url = URL.createObjectURL(blob);
                const loadingTask = getDocument(url);
                const pdf = await loadingTask.promise;
                setPdfDoc(pdf);
                setNumPages(pdf.numPages);
                
                // Renderizza la prima pagina
                await renderPage(1, pdf);
                
                // Esegui il click automatico SOLO al primo caricamento di qualsiasi PDF
                if (isInitialLoad.current ) {
                    isInitialLoad.current = false;
                    setTimeout(() => {
                        if (nextButtonRef.current && !nextButtonRef.current.disabled) {
                            nextButtonRef.current.click();
                        }
                    }, 500);
                }
            } catch (error) {
                console.error('Errore nel caricamento del PDF:', error);
            }
        };

        loadAndRenderPDF();

        return () => {
            if (pdfDoc) {
                pdfDoc.destroy();
            }
        };
    }, [folderName]);

    useEffect(() => {
        if (pdfDoc && pageNum) {
            queueRenderPage(pageNum);
        }
    }, [pageNum, pdfDoc]);

    const renderPage = async (num, pdfDocToRender = pdfDoc) => {
        if (!pdfDocToRender || !canvasRef.current) return;

        setPageRendering(true);
        
        try {
            const page = await pdfDocToRender.getPage(num);
            const viewport = page.getViewport({ scale });
            const canvas = canvasRef.current;
            const context = canvas.getContext('2d');
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            const renderContext = {
                canvasContext: context,
                viewport: viewport,
            };

            await page.render(renderContext).promise;
            setPageRendering(false);
            
            if (pageNumPending !== null) {
                const nextPage = pageNumPending;
                setPageNumPending(null);
                setPageNum(nextPage);
            }
        } catch (error) {
            console.error("Errore durante il rendering:", error);
            setPageRendering(false);
        }
    };

    const queueRenderPage = (num) => {
        if (pageRendering) {
            setPageNumPending(num);
        } else {
            renderPage(num);
        }
    };

    const goToPreviousPage = () => {
        if (pageNum <= 1) return;
        setPageNum(prev => prev - 1);
    };

    const goToNextPage = () => {
        if (!pdfDoc || !numPages || pageNum >= numPages) return;
        setPageNum(prev => prev + 1);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 2, mb: 2, borderBottom: 1, borderColor: 'divider' }}>
                <ButtonGroup variant="contained" color="primary" aria-label="PDF navigation">
                    <Button onClick={goToPreviousPage} disabled={pageNum <= 1 || pageRendering}>
                        Precedente
                    </Button>
                    <Typography variant="body1" sx={{ mx: 2, alignSelf: 'center' }}>
                        Pagina {pageNum} di {numPages || '--'}
                    </Typography>
                    <Button 
                        onClick={goToNextPage} 
                        disabled={!pdfDoc || !numPages || pageNum >= numPages || pageRendering}
                        ref={nextButtonRef}
                    >
                        Successiva
                    </Button>
                </ButtonGroup>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <canvas ref={canvasRef}></canvas>
            </Box>
        </Box>
    );
}

export default React.memo(PDFViewerDirect);