import React from 'react';
import ReactDOM from 'react-dom/client';
import { GoogleOAuthProvider } from '@react-oauth/google'; // Importa GoogleOAuthProvider
import App from './App';

const CLIENT_ID = '1075635104017-1f5e19tao7o1nqdq0jvi91088qf6kmju.apps.googleusercontent.com'; // Sostituisci con il tuo Client ID di Google

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <GoogleOAuthProvider clientId={CLIENT_ID}>
      <App />
    </GoogleOAuthProvider>
  // </React.StrictMode>
);
