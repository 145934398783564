import React, { useState, useEffect } from "react";
import { Paper, Box, Card } from "@mui/material";
import Multitrack from "./Player/Multitrack";
import SongSelect from "./Player/SongSelect";


import SuggestedExercises from "./SuggestedExercises";
import * as exerciseApi from '../exerciseApi';

const Dashboard = () => {
    console.log("Dashboard RENDER"); // 👈 Aggiunto il log

    const [selectedSong, setSelectedSong] = useState(null);
    const [exercises, setExercises] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [needsProfile, setNeedsProfile] = useState(false);
    

    const handleSongSelect = (songName) => {
        setSelectedSong(songName);
    };

    useEffect(() => {
        const fetchExercises = async () => {
            setLoading(true);
            setError(null);
            setNeedsProfile(false);

            try {
                const token = localStorage.getItem('jwtToken');
                if (!token) {
                    console.error("No token found. User is not logged in.");
                    setError("Not logged in.");
                    setLoading(false);
                    return;
                }
                const [fetchedExercises] = await Promise.all([
                    exerciseApi.getSuggestedExercises()
                ]);

                 if (fetchedExercises.needsProfile) {
                    setNeedsProfile(true);
                } else {
                    setExercises(fetchedExercises);
                }


            } catch (err) {
                console.error("Error fetching exercises:", err);
                setError(err.message || "Failed to fetch exercises.");
            } finally {
                setLoading(false);
            }
        };

        fetchExercises();
    }, []);

    return (
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", padding: 2 }}>
              
            <Card
                elevation={3}
                sx={{
                    width: "100%",
                    maxWidth: "1200px",
                    marginBottom: 2,
                    borderRadius: "15px",
                    backgroundColor: "#f5f5f5",
                }}
            >
                {loading ? (
                    <p>Loading exercises...</p>
                ) : error ? (
                    <p>Error: {error}</p>
                ) : needsProfile ? (
                    <p>Please complete your profile to get personalized exercise suggestions.</p>
                ) : (
                    <SuggestedExercises exercises={exercises} onSongSelect={handleSongSelect} />
                )}
            </Card>
            <SongSelect onSongSelect={handleSongSelect}/>
            <Paper
                elevation={3}
                sx={{
                    padding: 3,
                    width: "100%",
                    maxWidth: "1200px",
                    borderRadius: "15px",
                    backgroundColor: "#f5f5f5",
                }}
            >
                <Multitrack selectedSong={selectedSong} />
            </Paper>
        </Box>
    );
};

export default Dashboard;