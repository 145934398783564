// LoginModal.js
import React, { useState } from 'react';
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  Divider,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { GoogleLogin } from '@react-oauth/google';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: '10px',
};

//  Accept onGoogleLogin as a prop
const LoginModal = ({ open, handleClose, onSubmit, onGoogleLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    if (typeof onSubmit === 'function') {
      try {
        await onSubmit({ email, password });
      } catch (err) {
          if (err.response && err.response.data && err.response.data.message) {
              setError(err.response.data.message); // Display specific error from server
          } else {
              setError(err.message || 'Login failed. Please try again.');
          }
      }
    } else {
      console.error('onSubmit is not a function!');
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="login-modal"
      closeAfterTransition
    >
      <Box sx={modalStyle}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography id="login-modal" variant="h6" component="h2">
            Login
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          component="form"
          onSubmit={handleFormSubmit}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            mt: 2,
          }}
        >
          {error && (
            <Typography color="error" variant="body2">
              {error}
            </Typography>
          )}
          <TextField
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            required
          />
          <TextField
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            required
          />
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Login
          </Button>
        </Box>
        <Divider sx={{ my: 2 }} />
        <Box textAlign="center">
          <Typography variant="body1" gutterBottom>
            Or login with Google
          </Typography>
          {/* Pass onGoogleLogin to the GoogleLogin component */}
          <GoogleLogin
            onSuccess={onGoogleLogin} // THIS IS THE KEY FIX!
            onError={() => console.error('Google Login Failed')}
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default LoginModal;