import React, { useEffect, useRef, useState } from "react";
class BufferLoader {
    constructor(context, urlList, drawSampleCallback) { // 3rd arg is now drawSampleCallback
        this.context = context;
        this.urlList = urlList;
        this.drawSample = drawSampleCallback; // Only drawSample callback
        this.bufferList = [];
        this.loadCount = 0;
    }

    loadBuffer(url, index) {
        console.log("Loading and decoding file: " + url);
        const request = new XMLHttpRequest();
        request.open("GET", url, true);
        request.responseType = "arraybuffer";
        const loader = this;

        request.onload = function () {
            loader.context.decodeAudioData(
                request.response,
                function (buffer) { // success callback
                    console.log(
                        `Loaded and decoded track ${loader.loadCount + 1}/${loader.urlList.length}`
                    );
                    if (!buffer || typeof buffer.getChannelData !== 'function') {
                        console.error("BufferLoader: decodeAudioData did NOT return a valid AudioBuffer for URL:", url);
                        console.error("Buffer object:", buffer);
                        return;
                    }
                    loader.bufferList[index] = buffer;
                    loader.drawSample(buffer); // <---- Call drawSample with SINGLE buffer, not bufferList[index]
                    if (++loader.loadCount === loader.urlList.length) {
                        if (loader.onload) loader.onload(loader.bufferList); // Keep original onload for potential other uses
                    }
                },
                function (error) { // error callback
                    console.error("BufferLoader: Error DECODING AUDIO DATA for URL:", url);
                    console.error("Decoding error details:", error);
                    loader.drawSample(null); // Pass null to drawSample in error case
                }
            );
        };

        request.onerror = function () {
            console.error("BufferLoader: XHR error");
        };
        request.send();
    }

    load() {
        this.bufferList = [];
        this.loadCount = 0;
        console.log("Loading tracks... please wait.");
        for (let i = 0; i < this.urlList.length; ++i) {
            this.loadBuffer(this.urlList[i], i);
        }
    }
}
export default BufferLoader;