import React, { useState } from 'react';
import axios from 'axios';
import {
  Box,
  Alert,
  Typography,
  Button,
  CircularProgress
} from '@mui/material';

const ZipUploadPanel = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.name.endsWith('.zip')) {
      setSelectedFile(file);
      setUploadStatus(null);
    } else {
      setSelectedFile(null);
      setUploadStatus({ message: '❌ Il file non è un .zip valido.', severity: 'error' });
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) return;

    const formData = new FormData();
    formData.append('zipfile', selectedFile);

    setLoading(true);
    setUploadStatus(null);

    try {
      const response = await axios.post('https://www.jazzinator.com/api/file-manager/upload-zip', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });

      console.log('✔️ Upload completato:', response.data);
      setUploadStatus({ message: '✔️ Zip caricato e processato correttamente!', severity: 'success' });
      setSelectedFile(null);
    } catch (error) {
      console.error('❌ Errore durante l\'upload:', error);
      setUploadStatus({ message: '❌ Errore durante l\'upload del file zip.', severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ padding: 2, border: '2px dashed gray', backgroundColor: '#f9f9f9', textAlign: 'center' }}>
      <Typography variant="h6" gutterBottom>📦 Carica un file ZIP di multitracce</Typography>

      <input
        type="file"
        accept=".zip"
        onChange={handleFileChange}
        style={{ display: 'none' }}
        id="zip-upload-input"
      />
      <label htmlFor="zip-upload-input">
        <Button variant="outlined" component="span">Seleziona file ZIP</Button>
      </label>

      {selectedFile && (
        <Typography sx={{ mt: 1 }}>{selectedFile.name}</Typography>
      )}

      <Box sx={{ mt: 2 }}>
        <Button
          variant="contained"
          onClick={handleUpload}
          disabled={!selectedFile || loading}
        >
          {loading ? <CircularProgress size={24} /> : 'Carica'}
        </Button>
      </Box>

      {uploadStatus && (
        <Box sx={{ mt: 2 }}>
          <Alert severity={uploadStatus.severity}>
            {uploadStatus.message}
          </Alert>
        </Box>
      )}
    </Box>
  );
};

export default ZipUploadPanel;
