import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, Button, IconButton, Menu, MenuItem, Box, Divider, Avatar } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import LearningGoalProgress from "./LearningGoalProgress";

function Header({ user, onLogout, onLoginModalOpen }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [userMenuAnchorEl, setUserMenuAnchorEl] = useState(null);
  const [goalProgress, setGoalProgress] = useState(null);

  useEffect(() => {
    const fetchLearningGoalProgress = async () => {
      const token = localStorage.getItem('jwtToken');
      if (!token || !user) return;

      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/exercises/learning-goal-progress`, {
          headers: { Authorization: `Bearer ${token}` }
        });

        if (response.ok) {
          const progressData = await response.json();
          setGoalProgress(progressData);
        } else {
          console.error("Error fetching learning goal progress:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching learning goal progress:", error);
      }
    };

    fetchLearningGoalProgress();
  }, [user]);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleUserMenuOpen = (event) => {
    setUserMenuAnchorEl(event.currentTarget);
  };

  const handleUserMenuClose = () => {
    setUserMenuAnchorEl(null);
  };

  const handleLogout = () => {
    handleUserMenuClose();
    onLogout();
    localStorage.removeItem('jwtToken');
    localStorage.removeItem('user');
    setGoalProgress(null); // Clear progress on logout
  };

  return (
    <AppBar position="sticky" sx={{ backgroundColor: '#D4A373' }}>
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        {/* Logo and App Name */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h6" sx={{ fontWeight: 'bold', marginRight: 2 }}>
            Jazzinator
          </Typography>

          <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center', gap: 2 }}>
            {!user ? (
              <Button color="inherit" onClick={onLoginModalOpen} sx={{ fontWeight: 'bold' }}>Login</Button>
            ) : (
              <>
                <Button color="inherit" component={Link} to="/dashboard" sx={{ fontWeight: 'bold' }}>Dashboard</Button>
                <Button color="inherit" component={Link} to="/history" sx={{ fontWeight: 'bold' }}>Executions</Button>

                {user.role === 'admin' && (
                  <>
                    <Button color="inherit" component={Link} to="/chords" sx={{ fontWeight: 'bold' }}>Études</Button>
                    <Button color="inherit" component={Link} to="/famiglielivelli" sx={{ fontWeight: 'bold' }}>Levels & Families</Button>
                    <Button color="inherit" component={Link} to="/upload-zip" sx={{ fontWeight: 'bold' }}>Upload Zip</Button>
                  </>
                )}
              </>
            )}
          </Box>
        </Box>

        {/* Learning Goal Progress */}
        {user && goalProgress && (
          <Box sx={{ flexGrow: 1, maxWidth: '400px', mx: 2 }}>
            <LearningGoalProgress
              learningGoalName={goalProgress.learningGoalName}
              currentProgress={goalProgress.currentProgress}
              stepsRemaining={goalProgress.totalExercises - goalProgress.exercisesCompleted}
              totalSteps={goalProgress.totalExercises}
            />
          </Box>
        )}

        {/* User Icon and Menu */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {user && (
            <IconButton color="inherit" onClick={handleUserMenuOpen}>
              {user.profile_picture ? (
                <Avatar src={user.profile_picture} sx={{ width: 32, height: 32 }} />
              ) : (
                <Avatar sx={{ width: 32, height: 32 }}>{user.name?.charAt(0).toUpperCase()}</Avatar>
              )}
            </IconButton>
          )}

          <IconButton edge="end" color="inherit" aria-label="menu" onClick={handleMenuOpen} sx={{ display: { xs: 'block', md: 'none' } }}>
            <MenuIcon />
          </IconButton>
        </Box>

        {/* Mobile Navigation Menu */}
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
          <MenuItem onClick={handleMenuClose} component={Link} to="/">Home</MenuItem>
          {user && <MenuItem onClick={handleMenuClose} component={Link} to="/dashboard">Dashboard</MenuItem>}
          {user && <MenuItem onClick={handleMenuClose} component={Link} to="/history">Executions</MenuItem>}
          {user?.role === 'admin' && <MenuItem onClick={handleMenuClose} component={Link} to="/upload-zip">Upload Zip</MenuItem>}
          {!user ? (
            <MenuItem onClick={() => { handleMenuClose(); onLoginModalOpen(); }}>Login</MenuItem>
          ) : (
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          )}
        </Menu>

        {/* User Profile Menu */}
        <Menu anchorEl={userMenuAnchorEl} open={Boolean(userMenuAnchorEl)} onClose={handleUserMenuClose}>
          {user && (
            <Box sx={{ padding: '8px 16px' }}>
              <Typography variant="body1" sx={{ fontWeight: 'bold' }}>{user.name}</Typography>
              <Typography variant="body2" color="text.secondary">{user.role}</Typography>
            </Box>
          )}
          <Divider />
          <MenuItem component={Link} to="/profile" onClick={handleUserMenuClose}>Profile</MenuItem>
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}

export default Header;