import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';

import axios from 'axios';
import {
  Box,
  Alert,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Typography,
  CircularProgress
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const FileUploadPanel = forwardRef(({ onUploadComplete }, ref) => {



  const [uploadStatus, setUploadStatus] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchUploadedFiles();
  }, []);

  useImperativeHandle(ref, () => ({
    refresh: () => {
      fetchUploadedFiles();
    }
  }));

  
  const handleDrop = async (event) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    const mp3Files = files.filter(file => file.type === 'audio/mpeg');

    if (mp3Files.length === 0) {
      setUploadStatus([{ name: 'Nessun file MP3 valido.', status: 'error' }]);
      autoHideStatus();
      return;
    }

    const formData = new FormData();
    mp3Files.forEach(file => {
      formData.append('files', file);
    });

    setLoading(true);
    setUploadStatus([]);

    try {
      const response = await axios.post('https://www.jazzinator.com/api/file-manager/fileupload', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });

      console.log('File caricati:', response.data);
      setUploadStatus(mp3Files.map(file => ({ name: file.name, status: 'success' })));
      await fetchUploadedFiles();

    
      
    } catch (error) {
      console.error('Errore durante il caricamento dei file:', error);
      setUploadStatus(mp3Files.map(file => ({ name: file.name, status: 'error' })));
    } finally {
      setLoading(false);
      autoHideStatus();
    }
  };

  const autoHideStatus = () => {
    setTimeout(() => {
      setUploadStatus([]);
    }, 5000);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const fetchUploadedFiles = async () => {
    try {
      const response = await axios.get('https://www.jazzinator.com/api/file-manager/files');
      setUploadedFiles(response.data);
      if (onUploadComplete) {
        onUploadComplete(); // 🔄 aggiorna anche il padre
      }
    } catch (error) {
      console.error('Errore nel recupero dei file caricati:', error);
    }
  };




  const handleDeleteFile = async (fileName) => {
    try {
      await axios.delete('https://www.jazzinator.com/api/file-manager/file', { data: { fileName } });
      fetchUploadedFiles();

    } catch (error) {
      console.error('Errore durante la cancellazione del file:', error);
    }
  };

  return (
    <Box>
      <Box
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        sx={{ border: '2px dashed gray', padding: 2, marginBottom: 2, textAlign: 'center', backgroundColor: '#f9f9f9' }}
      >
        {loading ? <CircularProgress /> : 'Trascina qui i file MP3'}
      </Box>

      {uploadStatus.length > 0 && (
        <Box sx={{ marginBottom: 2 }}>
          {uploadStatus.map((file, index) => (
            <Alert key={index} severity={file.status === 'success' ? 'success' : 'error'}>
              {file.status === 'success' ? `✅ Caricato: ${file.name}` : `❌ Errore: ${file.name}`}
            </Alert>
          ))}
        </Box>
      )}

      <Typography variant="h6">🎵 File Caricati</Typography>
      <List>
        {uploadedFiles.map((file, index) => (
          <ListItem key={index}>
            <ListItemText primary={file.fullName} />
            <IconButton edge="end" onClick={() => handleDeleteFile(file.fullName)}>
              <DeleteIcon />
            </IconButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

}); 
export default FileUploadPanel;
