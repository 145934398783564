import React from "react";
import axios from "axios";
import { FormControl, FormLabel, Typography, Button, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import UploadFileIcon from "@mui/icons-material/UploadFile";

const HiddenInput = styled("input")({
  display: "none",
});

const PdfUploader = ({ folderName, currentValue, onChange }) => {
  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (!file || !folderName) return;

    const formData = new FormData();
    formData.append("folderName", folderName);
    formData.append("pdfFile", file);
    
    try {
      const res = await axios.post("https://www.jazzinator.com/api/file-manager/upload-pdf", formData);
      const pdfName = res.data.fileName;

      // Comunica al genitore il nuovo valore
      onChange({
        target: {
          name: "txt_spartito",
          value: pdfName
        }
      });
    } catch (err) {
      console.error("Errore durante l'upload del PDF:", err);
    }
  };

  return (
    <FormControl fullWidth margin="normal">
      
      <Box display="flex" alignItems="center" gap={2}>
        <label htmlFor="upload-pdf-button">
          <HiddenInput
            id="upload-pdf-button"
            type="file"
            accept="application/pdf"
            onChange={handleFileChange}
          />
          <Button
            variant="contained"
            component="span"
            startIcon={<UploadFileIcon />}
          >
            Scegli PDF
          </Button>
        </label>
        {currentValue && (
          <Typography variant="body2" sx={{ wordBreak: "break-word" }}>
            <strong>{currentValue}</strong>
          </Typography>
        )}
      </Box>
    </FormControl>
  );
};

export default PdfUploader;
