class Track {
    constructor(songName, instrument) {
      // Nome del brano: basso, chitarra, voce, ecc.
      this.name = instrument.name;
  
      // URL della traccia nel formato http://.../track/track_name
      this.url = `https://www.jazzinator.com/api/file-manager/mp3/${songName}/${instrument.sound}`;
  
      // Buffer audio decodificato
      this.decodedBuffer = null;
  
      // Picchi per il disegno del campione
      this.peaks = null;
  
      // Volume corrente
      this.volume = 1;
  
      // Panoramica corrente (sinistra/destra)
      this.panning = 0;
  
      // Stato mute/non mute
      this.muted = false;
  
      // Modalità solo
      this.solo = false;
  
      // Web audio nodes che compongono questa traccia
      this.sampleNode = null;
  
      // Nodo del volume per questa traccia
      this.volumeNode = null;
    }
  
    // Imposta il volume
    setVolume(value) {
      this.volume = value;
      if (this.volumeNode) {
        this.volumeNode.gain.value = value;
      }
    }
  
    // Attiva/disattiva il mute
    toggleMute() {
      this.muted = !this.muted;
      if (this.volumeNode) {
        this.volumeNode.gain.value = this.muted ? 0 : this.volume;
      }
    }
  
    // Attiva/disattiva la modalità solo
    toggleSolo() {
      this.solo = !this.solo;
    }
  
    // Assegna il buffer audio decodificato
    setDecodedBuffer(buffer) {
      this.decodedBuffer = buffer;
    }
  }
  
  export default Track;
  