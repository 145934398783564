import React, { useState, useEffect } from "react";
import FamiglieLivelliDetail from "./FamiglieLivelliDetail";

const FamiglieLivelli = () => {
  const [famiglie, setFamiglie] = useState([]);
  const [livelli, setLivelli] = useState([]);

  useEffect(() => {
    fetch("https://www.jazzinator.com/api/accordi/famiglie")
      .then((response) => response.json())
      .then((data) => setFamiglie(data))
      .catch((error) => console.error("Errore nel caricamento delle famiglie:", error));

    fetch("https://www.jazzinator.com/api/accordi/livelli")
      .then((response) => response.json())
      .then((data) => setLivelli(data))
      .catch((error) => console.error("Errore nel caricamento dei livelli:", error));
  }, []);

  return (
    <div className="overflow-x-auto">
<table className="table-auto border border-black border-collapse w-full">
<thead>
    <tr className="border border-black">
      <th className="border border-black px-4 py-2">Nome Famiglia</th>
      {livelli.map((livello) => (
        <th key={livello.Id} className="border border-black px-4 py-2">
          {livello.Nome_Livello}
        </th>
      ))}
    </tr>
  </thead>
  <tbody className="border border-black" style={{border:10 ,borderColor:"red" }}>
    {famiglie.map((famiglia) => (
      <tr key={famiglia.Id}  style={{backgroundColor:"bisque"}}>
        <td className="border border-black px-4 py-2">{famiglia.Nome_Famiglia}</td>
        {livelli.map((livello) => (
          <td key={`${famiglia.Id}-${livello.Id}`} className="border border-black px-4 py-2">
            <FamiglieLivelliDetail idfamiglia={famiglia.Id} idlivello={livello.Id} />
          </td>
        ))}
      </tr>
    ))}
  </tbody>
</table>

    </div>
  );
};

export default FamiglieLivelli;
