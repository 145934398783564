import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Container,
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  IconButton,
  Divider
} from '@mui/material';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import DeleteIcon from '@mui/icons-material/Delete';
import FileUploadPanel from './FileUploadPanel';
import TrackManager from './TrackManager';

function FileManager() {
  const [files, setFiles] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  
  const [newFolderName, setNewFolderName] = useState('');
  const [newFolderDialog, setNewFolderDialog] = useState(false);

  useEffect(() => {
    fetchFiles();

  }, []);

  const fetchFiles = async () => {
    try {
      const response = await axios.get('https://www.jazzinator.com/api/file-manager/list');
      setFiles(response.data);
    } catch (error) {
      console.error('Errore nel recupero dei file:', error);
    }
  };


  const handleOpenDialog = (folderName) => {
    setSelectedFolder(folderName);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedFolder(null);

  };

  const handleCreateFolder = async () => {
    try {
      await axios.post('https://www.jazzinator.com/api/file-manager/create-folder', {
        folderName: newFolderName
      });
      setNewFolderDialog(false);
      setNewFolderName('');
      fetchFiles();
    } catch (error) {
      console.error('Errore durante la creazione del nuovo brano:', error);
    }
  };

  const handleDeleteFolder = async (folderName) => {
    try {
      await axios.post('https://www.jazzinator.com/api/file-manager/delete-folder', { folderName });
      fetchFiles();
    } catch (error) {
      console.error('Errore durante la cancellazione della cartella:', error);
    }
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>Gestione File</Typography>

    


      <FileUploadPanel  />
      <Button variant="contained" color="primary" onClick={() => setNewFolderDialog(true)} sx={{ mb: 2 }}>
        Nuovo Brano
      </Button>
      
      <Dialog open={newFolderDialog} onClose={() => setNewFolderDialog(false)}>
        <DialogTitle>Crea Nuovo Brano</DialogTitle>
        <DialogContent>
          <TextField
            label="Nome Brano"
            value={newFolderName}
            onChange={(e) => setNewFolderName(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setNewFolderDialog(false)} color="secondary">Annulla</Button>
          <Button onClick={handleCreateFolder} color="primary">Crea</Button>
        </DialogActions>
      </Dialog>



      
      <Typography variant="h6">Brani</Typography>
      <List>
        {files.map((folder, index) => (
          <React.Fragment key={index}>
            <ListItem sx={{ borderBottom: '1px solid #ddd' }}>
              <ListItemText primary={folder} />
              <IconButton edge="end" onClick={() => handleOpenDialog(folder)}>
                <FolderOpenIcon />
              </IconButton>
              <IconButton edge="end" onClick={() => handleDeleteFolder(folder)}>
                <DeleteIcon />
              </IconButton>
            </ListItem>
            <Divider />
          </React.Fragment>
        ))}
      </List>

      {selectedFolder && (
        <TrackManager
          folderName={selectedFolder}
          open={openDialog}
          onClose={handleCloseDialog}
         
        />
      )}
    </Container>
  );
}

export default FileManager;
