// src/authApi.js
const API_URL = process.env.REACT_APP_API_URL;

export const register = async (userData) => {
    const response = await fetch(`${API_URL}/auth/register`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(userData),
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Registration failed');
    }
    return await response.json();
};

export const login = async ({ email, password }) => {
    const response = await fetch(`${API_URL}/auth/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password }),
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Login failed');
    }
    return await response.json();
};

export const logout = async (token) => {
  const response = await fetch(`${API_URL}/auth/logout`, {
        method: 'POST',
         headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
    });
	 if (!response.ok) {
		  const errorData = await response.json();
		  throw new Error(errorData.error || 'Logout failed');
	  }
};

export const verifyToken = async (token) => {
    const response = await fetch(`${API_URL}/auth/verify-token`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`,
        },
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Token verification failed');
    }
    return await response.json();
};

export const googleLogin = async (credential) => {
    const response = await fetch(`${API_URL}/auth/google-login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token: credential }),
    });
    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Google login failed');
    }
    return await response.json();
};