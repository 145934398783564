import React from 'react';
import { Box, Typography, LinearProgress, Tooltip } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const LearningGoalProgress = ({
  learningGoalName,
  currentProgress = 0,
  stepsRemaining,
  totalSteps,
}) => {
  const progressPercentage = Math.min(100, Math.max(0, currentProgress));
  const isComplete = progressPercentage === 100;

  return (
    <Box sx={{ 
      display: 'flex', 
      alignItems: 'center',
      gap: 1,
      minWidth: 200,
      maxWidth: 300,
      px: 1,
      py: 0.5,
      borderRadius: 1,
      bgcolor: 'background.paper'
    }}>
      {isComplete ? (
        <Tooltip title="Goal completed!">
          <CheckCircleIcon color="success" fontSize="small" />
        </Tooltip>
      ) : (
        <Typography variant="caption" color="text.secondary">
          {learningGoalName}
        </Typography>
      )}
      
      <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
        <LinearProgress
          variant="determinate"
          value={progressPercentage}
          sx={{
            flexGrow: 1,
            height: 6,
            borderRadius: 3,
            mr: 1
          }}
        />
        <Typography variant="caption" sx={{ minWidth: 30 }}>
          {progressPercentage.toFixed(0)}%
        </Typography>
      </Box>
    </Box>
  );
};

export default LearningGoalProgress;