import React from "react";
import { IconButton, Slider, Stack, Tooltip } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import StopIcon from "@mui/icons-material/Stop";
import LoopIcon from "@mui/icons-material/Loop";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
//import SkipPreviousIcon from "@mui/icons-material/SkipPrevious"; // Non usato
//import SkipNextIcon from "@mui/icons-material/SkipNext"; // Non usato
import { Button } from "@mui/material";
import { PlayArrow, Flag, FlagOutlined, Replay } from "@mui/icons-material";



const ControlPanel = ({
    onPlay,
    onPause,
    onStop,
    onLoopStart,
    onLoopEnd,
    onLoopReset,
    toggleLoop,
    isLoopActive,
    volume,
    setVolume,
    isPlaying,
    isSongLoaded,
    isSongInitialized,
    loopStart,
    loopEnd,
    progress,
    songDuration
}) => {


    const formatTime = (progress) => {
        if (!songDuration || isNaN(songDuration)) return "00:00:00:000"; // ✅ Previene errori

        const timeInSeconds = (progress / 100) * songDuration;
        const ms = Math.floor((timeInSeconds % 1) * 1000);
        const seconds = Math.floor(timeInSeconds) % 60;
        const minutes = Math.floor(timeInSeconds / 60) % 60;
        const hours = Math.floor(timeInSeconds / 3600);
        return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}:${String(ms).padStart(3, "0")}`;
    };


    return (
        <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            sx={{
                padding: "10px",
                borderRadius: "10px",
                backgroundColor: "#f5f5f5",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            }}
        >
            {!isSongInitialized && (
                <Tooltip title={isPlaying ? "" : "Play"}>
                    <span>
                        <IconButton
                            color="primary"
                            onClick={onPlay}
                            disabled={!isSongLoaded}
                        >
                            {isPlaying ? "" : <PlayArrowIcon />}

                        </IconButton>
                    </span>
                </Tooltip>
            )}


            {isSongInitialized && (
                <Tooltip title={isPlaying ? "Pause" : "Resume"}>
                    <span>
                        <IconButton
                            color="primary"
                            onClick={onPause}
                            disabled={!isSongLoaded}
                        >
                            {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}

                        </IconButton>
                    </span>
                </Tooltip>
            )}

            <Tooltip title="Stop">
                <span>
                    <IconButton
                        color="error"
                        onClick={onStop}
                        disabled={!isSongLoaded || !isPlaying}
                    >
                        <StopIcon />
                    </IconButton>
                </span>
            </Tooltip>

            <Tooltip title="Master Volume">
                <Slider
                    value={volume}
                    disabled={!isSongLoaded}
                    onChange={(e, newValue) => setVolume(newValue)}
                    min={0}
                    max={100}
                    valueLabelDisplay="auto"
                    valueLabelFormat={(value) => `${value}%`}
                    sx={{ width: 150 }}
                />
            </Tooltip>

            <Tooltip title={isLoopActive ? "Disable Loop" : "Enable Loop"}>
                <span>
                    <IconButton
                        color={isLoopActive ? "success" : "default"}
                        onClick={toggleLoop}
                    >
                        <LoopIcon />
                    </IconButton>
                </span>
            </Tooltip>



            {isLoopActive && (
                <>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onLoopStart}
                        disabled={!isSongLoaded}
                        startIcon={<Flag />} // ✅ Icona per il Loop Start
                    >
                        Set Loop Start
                    </Button>

                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={onLoopEnd}
                        disabled={!isSongLoaded}
                        startIcon={<FlagOutlined />} // ✅ Icona per il Loop End
                    >
                        Set Loop End
                    </Button>

                    <Button
                        variant="contained"
                        color="error"
                        onClick={onLoopReset}
                        startIcon={<Replay />} // ✅ Icona di reset intuitiva
                    >
                        Reset Loop
                    </Button>
                </>
            )}
            {/* 🔹 Informazioni di avanzamento e loop - MODIFICATO */}
            <div style={{
                display: 'flex',
                flexDirection: 'column', // Impila gli elementi verticalmente
                alignItems: 'flex-start', // Allinea a sinistra
                marginLeft: "20px",
                fontSize: "14px",
                fontWeight: "bold",
                fontFamily: 'monospace' // Usa un font monospace
                }}
            >
                <div>
                    Loop: {loopStart.toFixed(2)}% - {loopEnd.toFixed(2)}%
                </div>
                <div>
                    Avanzamento: {formatTime(progress)}
                </div>
            </div>



        </Stack>
    );
};


export default ControlPanel;