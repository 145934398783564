// src/exerciseApi.js
const API_URL = process.env.REACT_APP_API_URL;

const token = localStorage.getItem('jwtToken');

export const getSuggestedExercises = async () => {
    const response = await fetch(`${API_URL}/exercises/suggest`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`, // Include JWT for authentication
        },
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to get suggested exercises');
    }

    return await response.json();
};

export const postFeedback  = async (exerciseId,feedbackData) => {
    const response = await fetch(`${API_URL}/exercises/${exerciseId}/feedback`, {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(feedbackData)
});

if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.error || 'Failed to post feedback');
}

return await response.json();
};
