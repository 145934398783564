import React, { useState, useEffect } from "react";
import {
    Container,
    Typography,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    IconButton,
    TextField,
    TableSortLabel,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Checkbox,
    Radio,
    RadioGroup,
    FormControl,
    FormLabel,
    FormControlLabel,
    Select,
    MenuItem,
    Box
} from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import NewEtudeDialog from "./NewEtudeDialog";  // Import the New Etude Dialog
import * as XLSX from 'xlsx'; // Importa la libreria XLSX
import { FileDownload as FileDownloadIcon } from '@mui/icons-material'; // Importa l'icona di download



const ChordsComponent = () => {
  const [chords, setChords] = useState([]);
  const [open, setOpen] = useState(false);
  const [openNew, setOpenNew] = useState(false);
  const [selectedChord, setSelectedChord] = useState(null);
  const [families, setFamilies] = useState([]);
  const [levels, setLevels] = useState([]);
  // Initial form state (REDUCED fields)
  const [form, setForm] = useState({
      idfamiglia_fk: '',  // Will store the *ID* of the selected family
      idlivello_fk: '', // Will store the *ID* of the selected level
      i: '',
      ii: '',
      iii: '',
      iv: '',
      v: '',
      vi: '',
      note: '',
      file_path: '',     // You'll likely want to manage this separately
      Jazz_compliant: false, // Assuming these are booleans (tinyint(1) in MySQL)
      pentatonic: false, // Assuming these are for checkboxes
      arpeggio: false,
      triad_pairs: false,
      s30day: false,
      s22: false,
      everyday: false,
      txt_studio: '',
      txt_spartito: '',
      PDF_clean3:'',
  });

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("PDF_clean3");
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [chordToDelete, setChordToDelete] = useState(null);
    
    const [filters, setFilters] = useState({});
    const [familyNames, setFamilyNames] = useState({}); // Store family names by ID
    const [levelNames, setLevelNames] = useState({}); // Store family names by ID

    useEffect(() => {
        fetchChords();
        fetchFamilies();
        fetchLevels();
    }, []);

    const fetchChords = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/accordi`);
        if (!response.ok) throw new Error("Errore nel recupero degli accordi");
        const data = await response.json();
        setChords(data);
      } catch (error) {
        console.error(error.message);
      }
    };
  
    const fetchFamilies = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/accordi/famiglie`);
        if (!response.ok) {
          throw new Error("Failed to fetch families");
        }
        const data = await response.json();
        setFamilies(data);
  
        // Create a lookup for family names:
        const names = {};
        data.forEach(family => {
          names[family.Id] = family.Nome_Famiglia;
        });
        setFamilyNames(names);  // Store the mapping
      } catch (error) {
        console.error("Error fetching families:", error);
      }
    };

   const fetchLevels = async () => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/accordi/livelli`);
        if (!response.ok) {
            throw new Error("Failed to fetch levels");
        }
        const data = await response.json();
        setLevels(data); // Update the families state
        const names = {};
        data.forEach(level => {
          names[level.Id] = level.NomeLivello;
        });
        setLevelNames(names);  // Store the mapping
    } catch (error) {
        console.error("Error fetching levels:", error);
    }
};

const handleOpen = (chord) => {
  setSelectedChord(chord); // Set the selected chord for editing
  setForm(chord ? { ...chord } : {});  // Populate the form with the chord data if it's available
  setOpenNew(true); // Open the edit dialog
};

   const handleOpenNew = () => {
      setForm({
          idfamiglia_fk: '',  // Will store the *ID* of the selected family
          idlivello_fk: '', // Will store the *ID* of the selected level
          i: '',
          ii: '',
          iii: '',
          iv: '',
          v: '',
          vi: '',
          note: '',
          file_path: '',     // You'll likely want to manage this separately
          Jazz_compliant: false, // Assuming these are booleans (tinyint(1) in MySQL)
          pentatonic: false, // Assuming these are for checkboxes
          arpeggio: false,
          triad_pairs: false,
          s30day: false,
          s22: false,
          everyday: false,
          txt_studio: '',
          txt_spartito: '',
          PDF_clean3:'',
      });
      setOpenNew(true); // Open the "New" dialog
  };


    const handleClose = () => {
        setOpen(false);
        setOpenNew(false);
        setSelectedChord(null);
        // Reset the form on close.  VERY important for the "New" dialog.
         setForm({
            idfamiglia_fk: '',
            idlivello_fk: '',
            i: '',
            ii: '',
            iii: '',
            iv: '',
            v: '',
            vi: '',
            note: '',
            file_path: '',
            Jazz_compliant: false,
            pentatonic: false,
            arpeggio: false,
            triad_pairs: false,
            s30day: false,
            s22: false,
            everyday: false,
            txt_studio: '',
            txt_spartito: '',
            PDF_clean3:'',
        });
    };

    const handleChange = (e) => {
      const { name, value, type, checked } = e.target;
      const newValue = type === "checkbox" ? checked : value;
      setForm((prevForm) => ({ ...prevForm, [name]: newValue }));
  };

  const handleSave = async () => {
    try {
      const method = selectedChord?.id ? "PUT" : "POST";
      const url = selectedChord
        ? `${process.env.REACT_APP_API_URL}/accordi/${selectedChord.id}`
        : `${process.env.REACT_APP_API_URL}/accordi`;
  
      const response = await fetch(url, {
        method,
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(form),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Errore: ${errorData.error || response.statusText}`);
      }
  
      const result = await response.json();
  
      // Se è stato appena creato, passa a modifica
      if (method === "POST" && result.id) {
        setForm((prev) => ({ ...prev, id: result.id }));
        setSelectedChord({ ...form, id: result.id }); // Imposta per modalità modifica
      } else {
        handleClose();
      }
  
      fetchChords();
    } catch (error) {
      console.error("Errore durante il salvataggio:", error.message);
    }
  };
  

    const handleDelete = async (id) => {
     try {
       const response = await fetch(
         `${process.env.REACT_APP_API_URL}/accordi/${id}`,
         {
           method: "DELETE",
         }
       );
       if (!response.ok) throw new Error("Errore nella cancellazione dell'accordo");
       fetchChords();
     } catch (error) {
       console.error(error.message);
     }
    };


    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };
    const handleFilterChange = (event) => {
      const { name, value } = event.target;
      setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
    };

     const filteredChords = chords
        .filter((chord) =>
            Object.keys(filters).every((key) =>
                String(chord[key]).toLowerCase().includes(filters[key]?.toLowerCase())
            )
        )
        .sort((a, b) => {
            if (order === "desc") {
                return b[orderBy] < a[orderBy] ? -1 : 1;
            }
            return a[orderBy] < b[orderBy] ? -1 : 1;
        });


        const exportToExcel = () => {
          const data = filteredChords.map(chord => ({
              Name: chord.PDF_clean3,
              Family: familyNames[chord.idfamiglia_fk] || chord.idfamiglia_fk,
              Complexity: levelNames[chord.idlivello_fk] || chord.idlivello_fk,
              Notes: chord.note,
              FilePath: chord.file_path,
              JazzCompliant: chord.Jazz_compliant,
              Pentatonic: chord.pentatonic,
              Arpeggio: chord.arpeggio,
              TriadPairs: chord.triad_pairs,
              S30day: chord.s30day,
              S22: chord.s22,
              Everyday: chord.everyday,
              TxtStudio: chord.txt_studio,
              TxtSpartito: chord.txt_spartito
          }));
  
          const worksheet = XLSX.utils.json_to_sheet(data);
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, "Chords");
          XLSX.writeFile(workbook, "chords.xlsx");
      };
  


  return (
    <Container maxWidth={false}>
     
     
     <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ mb: 2 }}>
                <Box>
                    <Typography variant="h4" gutterBottom>
                        Gestione Brani
                    </Typography>
                    <Typography variant="subtitle1">
                        Totale brani: {chords.length} — Brani con file caricato: {chords.filter(c => c.file_path && c.file_path.trim() !== "").length}
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}> {/* Contenitore per allineare i pulsanti a destra */}
                    <Button variant="contained" color="primary" onClick={handleOpenNew} sx={{ mr: 1 }}>
                        Nuovo Brano
                    </Button>
                    <Button variant="contained" onClick={exportToExcel} startIcon={<FileDownloadIcon />} >
                        Esporta
                    </Button>
                </Box>
            </Box>


    
      <Paper sx={{ width: "100%", overflow: "hidden", marginTop: 2 }}>
        <TableContainer>
        <Table stickyHeader size="small" aria-label="chords table"
         sx={{
          '& td, & th': {
            paddingTop: '4px',
            paddingBottom: '4px',
            fontSize: '0.75rem', // testo più compatto
            lineHeight: 1.2
          }
        }}>
        <TableHead>
              <TableRow>

              <TableCell>
                <TableSortLabel
                    active={orderBy === "PDF_clean3"}
                    direction={orderBy === "PDF_clean3" ? order : "asc"}
                    onClick={() => handleRequestSort("PDF_clean3")}
                  >
                    Name
                  </TableSortLabel>
                   <TextField name="PDF_clean3"  variant="standard"  onChange={handleFilterChange} />
                </TableCell>




              <TableCell>
                  <TableSortLabel
                    active={orderBy === "idfamiglia_fk"}
                    direction={orderBy === "idfamiglia_fk" ? order : "asc"}
                    onClick={() => handleRequestSort("idfamiglia_fk")}
                  >
                    Family
                  </TableSortLabel>
                  <TextField name="idfamiglia_fk" variant="standard" onChange={handleFilterChange} />
                </TableCell>
                <TableCell>
                <TableSortLabel
                    active={orderBy === "idlivello_fk"}
                    direction={orderBy === "idlivello_fk" ? order : "asc"}
                    onClick={() => handleRequestSort("idlivello_fk")}
                  >
                    Complexity
                  </TableSortLabel>
                   <TextField name="idlivello_fk"  variant="standard"  onChange={handleFilterChange} />
                </TableCell>
                <TableCell>
                    <TableSortLabel
                        active={orderBy === "note"}
                        direction={orderBy === "note" ? order : "asc"}
                        onClick={() => handleRequestSort("note")}
                      >
                        Notes
                      </TableSortLabel>
                      <TextField  name="note" variant="standard" onChange={handleFilterChange} />
                </TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredChords.map((chord) => (
                <TableRow
                  key={chord.id}
                   sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    backgroundColor: chord.file_path ? 'inherit' : 'rgba(255, 0, 0, 0.1)'
                    }}
                >

                  <TableCell sx={{ whiteSpace: 'nowrap' }}>{chord.PDF_clean3}</TableCell>

                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    {familyNames[chord.idfamiglia_fk] || chord.idfamiglia_fk} {/* Display name or ID */}
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    {levelNames[chord.idlivello_fk] || chord.idlivello_fk} {/* Display name or ID */}
                  </TableCell>

                  <TableCell sx={{ whiteSpace: 'nowrap' }}>{chord.note}</TableCell>
               

                  <TableCell>
                  <IconButton onClick={() => handleOpen(chord)} color="primary">
                    <Edit />
                </IconButton>

                <IconButton
                    onClick={() => {
                      setChordToDelete(chord);
                      setDeleteConfirmOpen(true);
                    }}
                    color="secondary"
                    size="small"
                  >
                    <Delete />
                  </IconButton>

                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>


  {/* Dialog Component */}
  <NewEtudeDialog
    open={openNew}
    onClose={handleClose}
    families={families}
    levels={levels}
    form={form}
    handleChange={handleChange}
    handleSave={handleSave}
    isEditMode={selectedChord != null}  // Determine if in edit mode
/>

<Dialog
  open={deleteConfirmOpen}
  onClose={() => setDeleteConfirmOpen(false)}
>
  <DialogTitle>Conferma eliminazione</DialogTitle>
  <DialogContent>
    <Typography>Sei sicuro di voler eliminare il brano <b>{chordToDelete?.PDF_clean3}</b>?</Typography>
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setDeleteConfirmOpen(false)} color="primary">
      Annulla
    </Button>
    <Button
      onClick={() => {
        handleDelete(chordToDelete.id);
        setDeleteConfirmOpen(false);
        setChordToDelete(null);
      }}
      color="secondary"
    >
      Elimina
    </Button>
  </DialogActions>
</Dialog>












       





    </Container>




);



};




export default ChordsComponent;