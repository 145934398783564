import React, { useEffect, useState, useRef, useCallback } from "react"; // 🌎 Import useCallback

import TrackControls from "./TrackControls";
import Wave from "./Wave";
import Song from "./Song";
import Track from "./Track";
import ControlPanel from "./ControlPanel";
import PDFViewer from './PDFViewer';

const Multitrack = ({ selectedSong }) => {
  const containerRef = useRef(null);
  const [tracks, setTracks] = useState([]);
  const [dragging, setDragging] = useState(null);
  const [dragOffset, setDragOffset] = useState(0);
  const [cursorType, setCursorType] = useState("default");
  const [isLoopActive, setIsLoopActive] = useState(false);
  const [loopStart, setLoopStart] = useState(0);
  const [loopEnd, setLoopEnd] = useState(100);
  const [progress, setProgress] = useState(0);
  const [song, setSong] = useState(null);
  const [volume, setVolume] = useState(100);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isSongLoaded, setIsSongLoaded] = useState(false);
  const [isSongInitialized, setIsSongInitialized] = useState(false);
  const [elapsedTime, setElapsedTime] = useState("00:00:00:000");
  const [startTime, setStartTime] = useState(null); // 🔹 Memorizza il tempo di avvio
  const [isLoading, setIsLoading] = useState(false); // ✅ Already exists - loading state
  const [exerciseCompleted, setExerciseCompleted] = useState(false); // 🚩 Flag to prevent re-triggering

  useEffect(() => {
    if (song && song.initialized) {
      song.setVolume(volume / 100);
    }
  }, [volume, song]);

  useEffect(() => {
    if (!selectedSong) return;
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    loadSong(selectedSong, audioContext);
    return () => {
      audioContext.close();
    };
  }, [selectedSong]);


  const handleExerciseCompletion = useCallback(async (tempoImpiegato) => { // ✅ useCallback here
    if (!song || !tempoImpiegato || exerciseCompleted) return; // 🚩 Check flag and early return if already completed in this loop

    const token = localStorage.getItem('jwtToken');
    if (!token) {
      console.error("Nessun token JWT, l'utente deve essere autenticato.");
      return;
    }

    const difficoltaPercepita = "medio"; // ✅ Potremmo permettere all'utente di modificarlo
    const noteUtente = "Esercizio completato."; // ✅ Potremmo rendere il campo modificabile

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/profile/progress`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
        body: JSON.stringify({
          idEsercizio: song.id,
          tempo_impiegato: tempoImpiegato,
          difficolta_percepita: difficoltaPercepita,
          note_utente: noteUtente
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Errore durante il salvataggio del progresso.");
      }
    } catch (error) {
      console.error("Errore nel salvataggio dell'esercizio:", error);
    }
  }, [song, exerciseCompleted]); // ✅ Dependencies for useCallback - song and exerciseCompleted



  useEffect(() => {
    if (!song || !isLoopActive) return;

    const duration = song.getDuration();
    if (!duration || duration <= 0) return;

    // ✅ Converti loopStart e loopEnd in secondi
    const newLoopStart = (loopStart / 100) * duration;
    const newLoopEnd = (loopEnd / 100) * duration;

    console.log(`🔄 Loop aggiornato: ${newLoopStart}s - ${newLoopEnd}s`);

    // ✅ Aggiorna i valori nel brano
    song.loopStartTime = newLoopStart;
    song.loopEndTime = newLoopEnd;

    // Controlla la posizione attuale
    const elapsedTime = song.audioContext.currentTime - song.startTime;
    const currentTime = elapsedTime + newLoopStart;

    // Se il brano sta suonando e la posizione è fuori dal nuovo loop, riavvia
    if (isPlaying && (currentTime < newLoopStart || currentTime > newLoopEnd)) {
      console.log("🔄 Riavvio del loop con i nuovi limiti...");
      song.stop();
      setTimeout(() => {
        song.play(newLoopStart);
        setProgress((loopStart / 100) * 100);
      }, 100);
    }
  }, [loopStart, loopEnd]);


  const initializeAndLoadSong = async (songName) => {
    console.log("🔍 Inizializzazione canzone:", songName);
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();

    try {
      const response = await fetch(`https://www.jazzinator.com/api/track/${songName}`);
      const songData = await response.json();

      if (!songData || !songData.instruments || songData.instruments.length === 0) {
        console.error("❌ ERRORE: Nessun strumento trovato nella canzone.");
        return null;
      }

      const initializedSong = new Song(songData.id, audioContext);
      songData.instruments.forEach((instrument) => initializedSong.addTrack(instrument));

      const urls = initializedSong.getUrlsOfTracks();
      console.log("🎵 URLs delle tracce:", urls);

      const buffers = await Promise.all(
        urls.map(async (url) => {
          const response = await fetch(url);
          const arrayBuffer = await response.arrayBuffer();
          return audioContext.decodeAudioData(arrayBuffer);
        })
      );

      initializedSong.setDecodedAudioBuffers(buffers);
      initializedSong.setVolume(volume / 100);
      initializedSong.initialized = true;

      console.log("✅ Song completamente inizializzato:", initializedSong);
      return initializedSong;
    } catch (error) {
      console.error("❌ Errore durante il caricamento della canzone:", error);
      return null;
    }
  };

  const loadSong = async (songName, audioContext) => {
    try {
      // ✅ Ferma il brano precedente se sta suonando
      if (song && !song.paused) {
        console.log("🔴 Fermando il brano in riproduzione...");
        song.stop();
        setIsPlaying(false);
        setProgress(0);
      }
      setIsLoading(true); // ✅ Start loading
      console.log(`🎵 Caricamento della nuova canzone: ${songName}`);

      const response = await fetch(`https://www.jazzinator.com/api/track/${songName}`);
      const songData = await response.json();

      if (!songData || !songData.instruments || songData.instruments.length === 0) {
        console.error("⚠️ Nessuno strumento trovato per questa canzone.");
        alert("Errore: la canzone non ha strumenti disponibili.");
        setIsLoading(false); // ✅ Stop loading in case of error
        return;
      }

      const newSong = new Song(songData.id, audioContext);

      if (onSongLoaded) {
        onSongLoaded(newSong);
      }

      const trackObjects = songData.instruments.map(
        (instrument) => new Track(songData.id, instrument)
      );

      setSong(newSong); // ✅ Aggiorna la traccia attuale
      setTracks(trackObjects);
      setElapsedTime("00:00:00:000");

      // ✅ Mantieni attivo il loop se lo era prima
      if (isLoopActive) {
        const newDuration = newSong.getDuration();
        const newLoopStart = (loopStart / 100) * newDuration;
        const newLoopEnd = (loopEnd / 100) * newDuration;

        newSong.loopStartTime = newLoopStart;
        newSong.loopEndTime = newLoopEnd;

        setLoopStart(loopStart);
        setLoopEnd(loopEnd);
      } else {
        // ✅ Se il loop non era attivo, resettiamolo
        setLoopStart(0);
        setLoopEnd(100);
      }

      setIsSongInitialized(false);
      setIsPlaying(false);

    } catch (error) {
      console.error("Error loading song:", error);
      alert("Failed to load song. Please try again later.");
    } finally {
      setIsLoading(false); // ✅ Ensure loading is always set to false at the end
    }
  };


  const startProgressUpdate = (selectedSong, startTime = 0) => {
    if (!selectedSong || selectedSong.paused) return;

    const duration = selectedSong.getDuration();
    if (!duration || duration <= 0) return;

    const updateProgress = () => {
      if (selectedSong.paused) return;

      const currentTime = selectedSong.audioContext.currentTime;
      let elapsedTime = currentTime - selectedSong.startTime;

      if (isLoopActive) {
        const startLoopTime = (loopStart / 100) * duration;
        const endLoopTime = (loopEnd / 100) * duration;

        // Se il brano è fuori dal loop, riportiamolo a loopStart IMMEDIATAMENTE
        if (elapsedTime >= endLoopTime) {
          console.log("🔄 Loop terminato - Reset immediato della barra e della traccia!");

          // 1️⃣ STOPPA IMMEDIATAMENTE
          selectedSong.stop();

          // 2️⃣ RESETTA TEMPI INTERNI
          selectedSong.elapsedTimeSinceStart = startLoopTime;
          selectedSong.startTime = currentTime; // Sincronizza con il clock

          // 3️⃣ RESETTA LA BARRA PRIMA DI FAR PARTIRE L'AUDIO
          setProgress(loopStart);
          requestAnimationFrame(() => setProgress(loopStart));

          // 4️⃣ ASPETTA UN FRAME E RIPARTE ESATTAMENTE DA loopStart
          setTimeout(() => {
            selectedSong.play(startLoopTime);
            setProgress(loopStart);
            startProgressUpdate(selectedSong, startLoopTime);
          }, 10);

          return; // Evita aggiornamenti errati
        }

        // 🔹 Aggiorna la barra blu con posizione relativa al loop
        const relativeProgress = ((elapsedTime - startLoopTime) / (endLoopTime - startLoopTime)) * (loopEnd - loopStart);
        setProgress(loopStart + relativeProgress);
      } else {
        // 🔹 Aggiorna la progress bar normalmente
        setProgress((elapsedTime / duration) * 100);
      }

      requestAnimationFrame(updateProgress);
    };

    // ✅ FORZA LA UI PRIMA DI AVVIARE IL LOOP
    setProgress((startTime / duration) * 100);
    requestAnimationFrame(updateProgress);
  };


  const onSongLoaded = (loadedSong) => {
    console.log("🎵 onSongLoaded - song caricata:", loadedSong);

    if (!(loadedSong instanceof Song)) {
      console.error("❌ ERRORE: `loadedSong` non è un'istanza di Song.", loadedSong);
      return;
    }

    console.log("✅ Song caricata correttamente:", loadedSong);
    setSong(loadedSong);
    setIsSongLoaded(true);
    setIsLoopActive(true);
    setLoopStart(0);
    setLoopEnd(100);

    setTimeout(() => {
      setProgress(0);
    }, 50);
  };


  const updateTracks = () => {
    if (!selectedSong) return;

    selectedSong.setTrackVolumesDependingOnMuteSoloStatus(); // ✅ Aggiorna lo stato mute/solo
    setTracks([...selectedSong.tracks]); // ✅ Forza il re-render
  };

  const handleMouseLeave = () => {
    setCursorType("default");
  };


  const toggleLoop = () => {
    console.log("toggleLoop - song:", song); // ✅ Log `song` qui

    setIsLoopActive((prev) => {
      const newLoopActive = !prev;

      console.log("toggleLoop - Prima dell'aggiornamento: isLoopActive (prima):", prev, ", newLoopActive:", newLoopActive);

      if (song) { // ✅ Usa `song` qui
        console.log("toggleLoop - song object:", song);
        console.log("toggleLoop - song.loopMode prima dell'assegnazione:", song.loopMode);
        song.loopMode = newLoopActive; // ✅ Usa `song` qui
        console.log("toggleLoop - song.loopMode DOPO l'assegnazione:", song.loopMode);
      } else {
        console.log("toggleLoop - ATTENZIONE: song è null o undefined!");
      }

      if (!newLoopActive) {
        setLoopStart(0);
        setLoopEnd(100);
      }

      console.log("toggleLoop - Dopo dell'aggiornamento: isLoopActive (dopo):", newLoopActive, ", song.loopMode (attuale):", song ? song.loopMode : 'song è null');

      return newLoopActive;
    });
  };

  const onLoopStart = () => {
    if (progress !== null) {
      setLoopStart(progress);
      console.log(`Loop Start set at: ${progress}%`);
    }
  };


  const onLoopEnd = () => {
    if (progress !== null) {
      const duration = song?.getDuration() || 0;
      const minLoopSize = (5 / duration) * 100; // Converti 5s in percentuale

      if (progress - loopStart < minLoopSize) {
        return;
      }

      setLoopEnd(progress);
      console.log(`Loop End set at: ${progress}%`);
    }
  };

  const onLoopReset = () => {
    setLoopStart(0);
    setLoopEnd(100);
    console.log("Loop Reset - impostato all'intera traccia (0% - 100%)");
  };


  const handleClickOnWaveform = (e) => {
    if (!containerRef.current || !selectedSong) return;

    if (!(selectedSong instanceof Song) || selectedSong.tracks.length === 0) {
      console.error("selectedSong is not an instance of Song or has no tracks.");
      return;
    }

    const waveformElement = containerRef.current.querySelector("canvas");
    if (!waveformElement) return;

    const waveformRect = waveformElement.getBoundingClientRect();
    const clickX = e.clientX - waveformRect.left;
    const waveformWidth = waveformElement.clientWidth;

    const duration = selectedSong.getDuration();
    if (!duration || duration <= 0) return;

    const newProgress = (clickX / waveformWidth) * 100;
    const newTime = (newProgress / 100) * duration;

    selectedSong.stop();
    setTimeout(() => {
      //   selectedSong.play(newTime);
      setProgress(newProgress);
      //  setProgressPosition(newProgress);
      //  startProgressUpdate(selectedSong, newTime);
    }, 100);


    if (isLoopActive) {
      if (loopStart === null) {
        setLoopStart(newProgress); // Imposta l'inizio del loop
      } else {
        setLoopEnd(newProgress); // Imposta la fine del loop
      }
    }
  };
  const handleMouseDown = (e) => {
    if (!containerRef.current || !isLoopActive || loopStart === null || loopEnd === null) return;

    const waveformContainer = e.target.closest(".waveform-container");
    if (!waveformContainer) return;

    const waveformRect = waveformContainer.getBoundingClientRect();
    const clickX = e.clientX - waveformRect.left;
    const waveformWidth = waveformRect.width;

    const startX = (loopStart / 100) * waveformWidth;
    const endX = (loopEnd / 100) * waveformWidth;

    const clickThreshold = 10; // Distanza in pixel per riconoscere il click sulla barra

    if (Math.abs(clickX - startX) < clickThreshold) {
      setDragging("start");
    } else if (Math.abs(clickX - endX) < clickThreshold) {
      setDragging("end");
    } else if (clickX > startX && clickX < endX) {
      setDragging("move");
      setDragOffset(clickX - startX);
    }
  };


  const handleMouseMove = (e) => {
    if (!dragging || !containerRef.current || !isLoopActive || !song) return; // Aggiunto controllo su song

    const waveformContainer = e.target.closest(".waveform-container");
    if (!waveformContainer) return;

    const waveformRect = waveformContainer.getBoundingClientRect();
    const moveX = e.clientX - waveformRect.left;
    const waveformWidth = waveformRect.width;
    const newProgress = (moveX / waveformWidth) * 100;

    const duration = song.getDuration(); // Ottieni la durata
    if (!duration) return; // Controlla che la durata sia valida

    if (dragging === "start") {
      if (newProgress < loopEnd - 10) { // Evita sovrapposizioni
        setLoopStart(Math.max(0, newProgress));

        // Aggiornamento IMMEDIATO del loopStartTime in Song
        song.loopStartTime = (Math.max(0, newProgress) / 100) * duration;
        if (isPlaying) {
          const elapsedTime = song.audioContext.currentTime - song.startTime;
          if (elapsedTime < song.loopStartTime) {
            song.stop();
            song.play(song.loopStartTime);
          }
        }
      }
    } else if (dragging === "end") {
      if (newProgress > loopStart + 10) { // Evita sovrapposizioni
        setLoopEnd(Math.min(100, newProgress));

        // Aggiornamento IMMEDIATO del loopEndTime in Song
        song.loopEndTime = (Math.min(100, newProgress) / 100) * duration;
        if (isPlaying) {
          const elapsedTime = song.audioContext.currentTime - song.startTime;
          if (elapsedTime > song.loopEndTime) {
            song.stop();
            song.play(song.loopStartTime); //torna all'inizio del loop
          }
        }
      }
    } else if (dragging === "move") {
      const loopSize = loopEnd - loopStart;
      let newStart = newProgress - dragOffset;
      let newEnd = newStart + loopSize;

      if (newStart >= 0 && newEnd <= 100) {
        setLoopStart(newStart);
        setLoopEnd(newEnd);

        // Aggiornamento IMMEDIATO di entrambi i valori in Song
        song.loopStartTime = (newStart / 100) * duration;
        song.loopEndTime = (newEnd / 100) * duration;

        if (isPlaying) {
          const elapsedTime = song.audioContext.currentTime - song.startTime;
          //se sono fuori dal loop, riparto dall'inizio del loop
          if (elapsedTime < song.loopStartTime || elapsedTime > song.loopEndTime) {
            song.stop();
            song.play(song.loopStartTime);
          }
        }
      }
    }
  };

  const handleMouseUp = () => {
    if (!dragging || !song) return; // Aggiunto controllo su song

    // Non c'è più bisogno di fare nulla qui, perché l'aggiornamento
    // del loop avviene già in handleMouseMove in tempo reale.

    setDragging(null);
  };

  useEffect(() => {
    const handleUnload = () => {
      if (isPlaying && startTime) {
        const tempoImpiegato = Math.round((Date.now() - startTime) / 1000);
        handleExerciseCompletion(tempoImpiegato, true); // ✅ Salvataggio forzato
      }
    };

    window.addEventListener("beforeunload", handleUnload);
    return () => {
      window.removeEventListener("beforeunload", handleUnload);
    };
  }, [isPlaying, startTime]);


  const handlePlay = async () => {
    if (!song) {
      console.log("⚠️ Nessuna canzone selezionata per la riproduzione.");
      return;
    }

    // Se il brano non è ancora stato inizializzato, lo carichiamo
    if (!song.initialized) {
      console.log("📥 Caricamento iniziale della canzone...");
      const loadedSong = await initializeAndLoadSong(song.name);
      if (loadedSong) {
        setSong(loadedSong);
        setIsSongInitialized(true);

        // 🔹 Controlliamo se il loop è attivo e impostiamo il punto di partenza
        const startPoint = isLoopActive ? (loopStart / 100) * loadedSong.getDuration() : 0;
        console.log(`▶️ Riproduzione dal secondo ${startPoint}`);

        loadedSong.play(startPoint);
        setIsPlaying(true);
        setStartTime(Date.now());
        startProgressUpdate(loadedSong, startPoint);
      }
    } else {
      // 🔹 Se il brano è già inizializzato, riprendiamo dal punto corretto
      const startPoint = isLoopActive ? (loopStart / 100) * song.getDuration() : song.elapsedTimeSinceStart;
      console.log(`▶️ Riproduzione dal secondo ${startPoint} (Loop attivo: ${isLoopActive})`);

      song.play(startPoint);
      setIsPlaying(true);
      setStartTime(Date.now());
      startProgressUpdate(song, startPoint);
    }
  };


  const handlePause = () => {
    if (song && !song.paused) {
      song.pause();
      setIsPlaying(false);

      if (song.progressInterval) {
        clearInterval(song.progressInterval);
        song.progressInterval = null;
      }
    } else if (song && song.paused) {
      song.play(song.elapsedTimeSinceStart); // ✅ Riparte dal punto corretto
      setIsPlaying(true);
      startProgressUpdate(song, song.elapsedTimeSinceStart);
    }
  };


  const handleStop = () => {
    if (!song || !(song instanceof Song)) {
      console.warn("Cannot stop: `song` is not a valid Song instance.", song);
      return;
    }

    song.stop();

    if (song.progressInterval) {
      clearInterval(song.progressInterval);
      song.progressInterval = null;
    }

    setIsPlaying(false);
    setIsSongInitialized(false);
    setProgress(0); // ✅ Resetta la progress bar

    // ✅ Mantieni il loop attivo e i valori del loop
    setIsLoopActive(true);
    setLoopStart((prev) => prev); // ✅ Mantieni il valore precedente
    setLoopEnd((prev) => prev);

    console.log("⏹️ Stop premuto - Loop mantenuto:", { loopStart, loopEnd });
  };


  return (
    <>
      <ControlPanel
        onPlay={handlePlay}
        onPause={handlePause}
        onStop={handleStop}
        onLoopStart={onLoopStart}
        onLoopEnd={onLoopEnd}
        onLoopReset={onLoopReset}
        toggleLoop={toggleLoop}
        isLoopActive={isLoopActive}
        volume={volume}
        setVolume={setVolume}
        isPlaying={isPlaying}
        isSongLoaded={isSongLoaded}
        isSongInitialized={isSongInitialized}
        loopStart={loopStart}
        loopEnd={loopEnd}
        progress={progress}
        songDuration={(song && typeof song.getDuration === "function") ? song.getDuration() : 0} // ✅ FIX
      />

      <div
        id="soundContainer"
        ref={containerRef}
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          gap: "0px",
          border: "1px solid #ddd",
          backgroundColor: "#ffffff",
          padding: "10px",
          userSelect: "none",
          // ✅ Prevent interaction when loading
          pointerEvents: isLoading ? "none" : "auto",
          opacity: isLoading ? 0.6 : 1, // Optional: visual feedback of loading
        }}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
      >
        {/* Loading Overlay - Conditionally rendered */}
        {isLoading && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(255, 255, 255, 0.7)", // Semi-transparent white background
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 1000, // Ensure it's on top
            }}
          >
            <div className="loader"></div> {/* Circular progress indicator */}
          </div>
        )}


        <div
          style={{
            position: "absolute",
            top: 0, // Allineata con le tracce
            left: "390px",
            width: "calc(100% - 400px)",
            height: "100%",
            zIndex: 500,
            pointerEvents: "none",
          }}
        >
          {/* ✅ Barra Blu che avanza con la riproduzione */}
          {/* 🔹 Progress bar limitata al loop */}
          <div
            style={{
              position: "absolute",
              top: 0,
              left: `${progress}%`,
              width: "3px",
              height: "100%",
              backgroundColor: "CornflowerBlue",
              zIndex: 20,
              transition: "left 0.1s linear",
            }}
          />


          {isLoopActive && loopStart !== null && loopEnd !== null && (
            <>
              {/* Area evidenziata del loop */}
              <div
                style={{
                  position: "relative",
                  top: 0,
                  left: `${loopStart}%`,
                  width: `${loopEnd - loopStart}%`,
                  height: "100%",
                  backgroundColor: "rgba(0, 255, 0, 0.2)",
                  zIndex: 501,
                  cursor: "grab", // ✅ Consente di trascinare l'intera area
                }}
              />

              {/* Barra verde sinistra (loop start) */}
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: `${loopStart}%`,
                  width: "4px",
                  height: "100%",
                  backgroundColor: "green",
                  zIndex: 502,
                  cursor: cursorType, // ✅ Usa lo stato per il cursore
                }}
                onMouseEnter={() => setCursorType("ew-resize")}
                onMouseLeave={() => setCursorType("default")}
              />

              {/* Barra verde destra (loop end) */}
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: `${loopEnd}%`,
                  width: "4px",
                  height: "100%",
                  backgroundColor: "green",
                  zIndex: 502,
                  cursor: cursorType, // ✅ Usa lo stato per il cursore
                }}
                onMouseEnter={() => setCursorType("ew-resize")}
                onMouseLeave={() => setCursorType("default")}
              />
            </>
          )}
        </div>


        {tracks.map((track, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              alignItems: "center",
              gap: "0px",
              borderBottom: "1px solid #555",
              padding: "5px 0",
            }}
          >
            <div style={{ flex: "1" }}>
              <TrackControls track={track} song={song} trackIndex={index} updateTracks={updateTracks} />
            </div>
            <div style={{ flex: "3" }} className="waveform-container"
              onClick={handleClickOnWaveform}
              onMouseLeave={handleMouseLeave}
            >
              <Wave track={track} />
            </div>
          </div>
        ))}
      </div>

      <PDFViewer folderName={selectedSong} />

    </>


  );
};

export default Multitrack;