// NewEtudeDialog.js
import React, { useState, useEffect, useRef  } from "react";

import axios from "axios";
import { Paper } from '@mui/material';

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormLabel,
    Select,
    MenuItem,
    TextField,
    FormControlLabel,
    Checkbox,
    Button,
    ListItemSecondaryAction,

    Typography,
    List,
    ListItem,
    ListItemText,
    IconButton,
    FormControlLabel as MuiFormControlLabel
  

} from "@mui/material";
import Grid from "@mui/material/Grid"; 


import DeleteIcon from "@mui/icons-material/Delete";
import EditTrackDialog from "./EditTrackDialog"; // Import Edit Dialog
import PdfUploader from "./FileManager/PdfUploader"; 
import FileUploadPanel from './FileManager/FileUploadPanel';



const NewEtudeDialog = ({ open, onClose, families, levels, form, handleChange, handleSave, isEditMode }) => {

 const [tracks, setTracks] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [step, setStep] = useState("basic"); // 'basic' o 'full'
  const uploadPanelRef = useRef();


  useEffect(() => {
    if (open && form.PDF_clean3) {
      fetchTracks(form.PDF_clean3);
      fetchUploadedFiles();
    }
  
    if (!isEditMode) {
      setStep("basic");
    } else {
      setStep("full");
    }
  }, [open, form.PDF_clean3, isEditMode]);
  
  useEffect(() => {
    if (open && form.PDF_clean3) {
      fetchTracks(form.PDF_clean3);
      fetchUploadedFiles();
    }
  
    if (!form.id) {
      setStep("basic");
    } else {
      setStep("full");
    }
  }, [open, form.PDF_clean3, form.id]);
  


  useEffect(() => {
    if (open && form.PDF_clean3) {
      fetchTracks(form.PDF_clean3);
      fetchUploadedFiles();
    }else{
        setTracks([]);
        setUploadedFiles([]);

    }
  }, [open, form.PDF_clean3]); // Adesso le tracce si ricaricano a ogni cambio di brano!

  const fetchTracks = async (folderName) => {
    try {
      const response = await axios.get(`https://www.jazzinator.com/api/file-manager/list/${folderName}`);
      setTracks(response.data);
    } catch (error) {
      console.error("Errore nel recupero delle tracce:", error);
    }
  };

  const fetchUploadedFiles = async () => {
    try {
      const response = await axios.get("https://www.jazzinator.com/api/file-manager/files");
      setUploadedFiles(response.data);
    } catch (error) {
      console.error("Errore nel recupero dei file caricati:", error);
    }
  };

  const handleDeleteTrack = async (track) => {
    try {
      await axios.delete(`https://www.jazzinator.com/api/track`, { data: { fileName: track, folderName: form.PDF_clean3 } });
      fetchTracks(form.PDF_clean3); // Ricarica subito le tracce
    } catch (error) {
      console.error("Errore durante la cancellazione della traccia:", error);
    }
  };
  const isBasicFormValid = () => {
    return form.PDF_clean3?.trim() !== "" &&
           form.idfamiglia_fk?.toString().trim() !== "" &&
           form.idlivello_fk?.toString().trim() !== "";
  };
  
  const handleAddTrack = async (file) => {
    const trackName = file.trackName;
    const trackNumber = file.trackNumber;
  
    if (!isTrackValid(file)) return;
  
    try {
      await axios.post("https://www.jazzinator.com/api/file-manager/upload-metadata", {
        folderName: form.PDF_clean3,
        trackName,
        trackNumber,
        uploadedFileName: file.fullName
      });
  
      // Aggiorna lista tracce e lista file caricati
      fetchTracks(form.PDF_clean3);
      fetchUploadedFiles();
  
      setUploadedFiles((prev) => prev.filter((f) => f.fullName !== file.fullName));
  
      // 🔄 Richiama refresh anche sul FileUploadPanel tramite ref
      if (uploadPanelRef.current) {
        uploadPanelRef.current.refresh();
      }
    } catch (error) {
      console.error("Errore durante lo spostamento del file:", error);
    }
  };
  
  

  const handleInputChange = (index, field, value) => {
    setUploadedFiles((prev) => {
      const updatedFiles = [...prev];
      updatedFiles[index] = { ...updatedFiles[index], [field]: value };
      return updatedFiles;
    });
  };

  const isTrackValid = (file) => {
    return file.trackNumber !== undefined && file.trackNumber !== "" &&
           !isNaN(file.trackNumber) && 
           file.trackName !== undefined && file.trackName.trim() !== "";
  };





    return (
<Dialog open={open} onClose={onClose} fullWidth maxWidth="xl">
      <EditTrackDialog open={open} onClose={onClose} form={form} onChange={handleChange} onSave={handleSave}  />
            <DialogTitle>{isEditMode ? "Edit Étude" : "New Étude"}</DialogTitle>
            <DialogContent>
                {/* Family Selection (Dropdown) */}
                <FormControl fullWidth margin="dense">
                
                <TextField
  fullWidth
  margin="dense"
  name="PDF_clean3"
  label="Full Name"
  value={form.PDF_clean3}
  onChange={handleChange}
  disabled={!!form.id} // disabilitato se l'id esiste (cioè è stato salvato)
/>


                    <FormLabel component="legend">Family</FormLabel>
                    <Select
                        name="idfamiglia_fk"
                        value={form.idfamiglia_fk}
                        onChange={handleChange}
                        displayEmpty
                    >
                        <MenuItem value="" disabled>
                            Select a Family
                        </MenuItem>
                        {families.map((family) => (
                            <MenuItem key={family.Id} value={family.Id}>
                                {family.Nome_Famiglia}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                {/* Level Selection (Dropdown) */}
                <FormControl fullWidth margin="dense">
                    <FormLabel component="legend">Level</FormLabel>
                    <Select
                        name="idlivello_fk"
                        value={form.idlivello_fk}
                        onChange={handleChange}
                        displayEmpty
                    >
                        <MenuItem value="" disabled>
                            Select a Level
                        </MenuItem>
                        {levels.map((level) => (
                            <MenuItem key={level.Id} value={level.Id}>
                                {level.NomeLivello}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {step === "full" && (
  <>
<Grid container spacing={2}>
  <Grid item xs={2}>
    <TextField margin="dense" name="i" label="i" value={form.i} onChange={handleChange} fullWidth />
    <FormControlLabel control={<Checkbox checked={form.s30day} onChange={handleChange} name="s30day" />} label="30-day" />
  </Grid>
  <Grid item xs={2}>
    <TextField margin="dense" name="ii" label="ii" value={form.ii} onChange={handleChange} fullWidth />
    <FormControlLabel control={<Checkbox checked={form.triad_pairs} onChange={handleChange} name="triad_pairs" />} label="Triad Pairs" />
  </Grid>
  <Grid item xs={2}>
    <TextField margin="dense" name="iii" label="iii" value={form.iii} onChange={handleChange} fullWidth />
    <FormControlLabel control={<Checkbox checked={form.pentatonic} onChange={handleChange} name="pentatonic" />} label="Pentatonic" />
  </Grid>
  <Grid item xs={2}>
    <TextField margin="dense" name="iv" label="iv" value={form.iv} onChange={handleChange} fullWidth />
    <FormControlLabel control={<Checkbox checked={form.s22} onChange={handleChange} name="s22" />} label="2+2" />
  </Grid>
  <Grid item xs={2}>
    <TextField margin="dense" name="v" label="v" value={form.v} onChange={handleChange} fullWidth />
    <FormControlLabel control={<Checkbox checked={form.everyday} onChange={handleChange} name="everyday" />} label="Everyday" />
  </Grid>
  <Grid item xs={2}>
    <TextField margin="dense" name="vi" label="vi" value={form.vi} onChange={handleChange} fullWidth />
    <FormControlLabel control={<Checkbox checked={form.arpeggio} onChange={handleChange} name="arpeggio" />} label="Arpeggio" />
  </Grid>
</Grid>





<Grid container spacing={2}>
  <Grid item xs={6}>
    <TextField fullWidth margin="dense" name="note" label="Notes" value={form.note} onChange={handleChange} />
  </Grid>
  <Grid item xs={6}>
    <TextField fullWidth margin="dense" name="txt_studio" label="Studio" value={form.txt_studio} onChange={handleChange} />
  </Grid>
</Grid>


    <PdfUploader
      folderName={form.PDF_clean3}
      currentValue={form.txt_spartito}
      onChange={handleChange}
    />



<Paper
  elevation={3}
  sx={{
    backgroundColor: '#f3f6f9',
    borderRadius: 2,
    padding: 3,
    marginTop: 3,
  }}
>
<Typography variant="h6" sx={{ mb: 2 }}>
    Gestione Tracce Audio
  </Typography>

            <Grid container spacing={2}
           
            >


              <Grid item xs={4}
               sx={{
                border: '1px solid #ccc',
                borderRadius: 2,
                padding: 2,
                backgroundColor: '#fff'
              }}>
                <Typography variant="h6">Caricamento file</Typography>
                <FileUploadPanel
                  ref={uploadPanelRef}
                  onUploadComplete={() => {
                    fetchUploadedFiles();
                    fetchTracks(form.PDF_clean3);
                  }}
                />
              </Grid>


              {/* Tracce Caricate */}
              <Grid item xs={4}
              sx={{
                border: '1px solid #ccc',
                borderRadius: 2,
                padding: 2,
                backgroundColor: '#fff'
              }}
              >
                <Typography variant="h6">Tracce Caricate (Uploads)</Typography>
                <List>
                  {uploadedFiles.map((file, index) => (
                    <ListItem key={index}>
                      <ListItemText primary={file.fullName} />
                      <TextField
                        label="Numero Traccia"
                        value={file.trackNumber || ""}
                        onChange={(e) => handleInputChange(index, "trackNumber", e.target.value)}
                        style={{ marginRight: "10px" }}
                        error={file.trackNumber !== undefined && isNaN(file.trackNumber)}
                        helperText={file.trackNumber !== undefined && isNaN(file.trackNumber) ? "Inserire un numero valido" : ""}
                      />
                      <TextField
                        label="Nome Traccia"
                        value={file.trackName || ""}
                        onChange={(e) => handleInputChange(index, "trackName", e.target.value)}
                        style={{ marginRight: "10px" }}
                      />
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleAddTrack(file)}
                        disabled={!isTrackValid(file)}
                      >
                        Aggiungi al Brano
                      </Button>
                    </ListItem>
                  ))}
                </List>






              </Grid>



              <Grid item xs={4}
              sx={{
                border: '1px solid #ccc',
                borderRadius: 2,
                padding: 2,
                backgroundColor: '#fff'
              }}
              >

                <Typography variant="h6">Tracce del brano</Typography>
                <List>
                  {tracks.map((track, index) => (
                    <ListItem key={index}>
                      <ListItemText primary={track} />
                      <ListItemSecondaryAction>
                        <IconButton edge="end" onClick={() => handleDeleteTrack(track)}>
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </Grid>



            </Grid>
</Paper>
          </>
        )}




      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleSave}
          color="primary"
          variant="contained"
          disabled={!isBasicFormValid()}
        >
          Save
        </Button>



      </DialogActions>
    </Dialog>
  );
};

export default NewEtudeDialog;
