import React, { useState, useEffect } from "react";

const FamiglieLivelliDetail = ({ idfamiglia, idlivello }) => {
  const [tags, setTags] = useState([]);

  useEffect(() => {
    fetch(`https://www.jazzinator.com/api/accordi/famiglielivelli/${idfamiglia}/${idlivello}`)
      .then((response) => response.json())
      .then((data) => setTags(data.length ? data.map(item => item.pdf_clean3+'-'+item.note || "-") : ["-"]))
      .catch((error) => console.error("Errore nel caricamento dei dati:", error));
  }, [idfamiglia, idlivello]);

  return (
    <div className="tag-container flex flex-col gap-2 border border-gray-400 p-2">
      {tags.map((tag, index) => (
        <button key={index} className="bg-blue-500 text-white px-4 py-2 rounded shadow-md hover:bg-blue-700 border border-gray-300">
          {tag}
        </button>
      ))}
    </div>
  );
};

export default FamiglieLivelliDetail;
