import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  FormHelperText,
  FormControlLabel as MuiFormControlLabel
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

const EditTrackDialog = ({ open, onClose, form, onChange, onSave }) => {
  const [tracks, setTracks] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  useEffect(() => {
    if (open && form.PDF_clean3) {
      fetchTracks(form.PDF_clean3);
      fetchUploadedFiles();
    }else{
        setTracks([]);
        setUploadedFiles([]);

    }
  }, [open, form.PDF_clean3]); // Adesso le tracce si ricaricano a ogni cambio di brano!

  const fetchTracks = async (folderName) => {
    try {
      const response = await axios.get(`https://www.jazzinator.com/api/file-manager/list/${folderName}`);
      setTracks(response.data);
    } catch (error) {
      console.error("Errore nel recupero delle tracce:", error);
    }
  };

  const fetchUploadedFiles = async () => {
    try {
      const response = await axios.get("https://www.jazzinator.com/api/file-manager/files");
      setUploadedFiles(response.data);
    } catch (error) {
      console.error("Errore nel recupero dei file caricati:", error);
    }
  };

  const handleDeleteTrack = async (track) => {
    try {
      await axios.delete(`https://www.jazzinator.com/api/track`, { data: { fileName: track, folderName: form.PDF_clean3 } });
      fetchTracks(form.PDF_clean3); // Ricarica subito le tracce
    } catch (error) {
      console.error("Errore durante la cancellazione della traccia:", error);
    }
  };

  const handleAddTrack = async (file) => {
    const trackName = file.trackName;
    const trackNumber = file.trackNumber;

    if (!isTrackValid(file)) return;

    try {
      await axios.post("https://www.jazzinator.com/api/file-manager/upload-metadata", {
        folderName: form.PDF_clean3,
        trackName,
        trackNumber,
        uploadedFileName: file.fullName
      });
      fetchTracks(form.PDF_clean3); // Ricarica subito le tracce
      setUploadedFiles((prev) => prev.filter((f) => f.fullName !== file.fullName));
    } catch (error) {
      console.error("Errore durante lo spostamento del file:", error);
    }
  };

  const handleInputChange = (index, field, value) => {
    setUploadedFiles((prev) => {
      const updatedFiles = [...prev];
      updatedFiles[index] = { ...updatedFiles[index], [field]: value };
      return updatedFiles;
    });
  };

  const isTrackValid = (file) => {
    return file.trackNumber !== undefined && file.trackNumber !== "" &&
           !isNaN(file.trackNumber) && 
           file.trackName !== undefined && file.trackName.trim() !== "";
  };

  return (
      <DialogContent>
        <Grid container spacing={2}>
          {/* Colonna 1 - Editor dei campi */}

       
        </Grid>
      </DialogContent>
     

  );
};

export default EditTrackDialog;
