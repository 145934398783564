import React, { useState, useEffect } from "react";
import { Box, Typography, Slider, IconButton, Tooltip } from "@mui/material";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import HeadsetIcon from "@mui/icons-material/Headset";
import HeadsetOffIcon from "@mui/icons-material/HeadsetOff";

const TrackControls = ({ track, trackIndex, song }) => {
  const [volume, setVolume] = useState(track.volume || 1);
  const [muted, setMuted] = useState(track.muted || false);
  const [solo, setSolo] = useState(track.solo || false);

  useEffect(() => {
    console.log(`🎚️ Track ${trackIndex} - Volume iniziale:`, track.volume);
    console.log(`🔇 Track ${trackIndex} - Mute iniziale:`, track.muted);
    console.log(`🎧 Track ${trackIndex} - Solo iniziale:`, track.solo);
  }, []);

  // ✅ Modifica il volume della traccia
  const handleVolumeChange = (newValue) => {
    console.log(`🎚️ Cambiamento volume traccia ${trackIndex}: ${newValue}`);
    setVolume(newValue);

    if (song && typeof song.setVolumeOfTrack === "function") {
      console.log(`🔍 Chiamata a setVolumeOfTrack(${newValue}, ${trackIndex})`);
      song.setVolumeOfTrack(newValue, trackIndex);
    } else {
      console.warn(`⚠️ song.setVolumeOfTrack non è una funzione o song non è definito!`);
    }
  };

  // ✅ Attiva/disattiva il mute
  const toggleMute = () => {
    const newMuted = !muted;
    console.log(`🔇 Mute cambiato per traccia ${trackIndex}: ${newMuted}`);
    setMuted(newMuted);

    if (song && song.tracks[trackIndex]) {
      song.tracks[trackIndex].muted = newMuted;
      console.log(`🔍 Chiamata a setTrackVolumesDependingOnMuteSoloStatus()`);
      song.setTrackVolumesDependingOnMuteSoloStatus();
    } else {
      console.warn(`⚠️ song.tracks[${trackIndex}] non esiste!`);
    }
  };

  // ✅ Attiva/disattiva il solo
  const toggleSolo = () => {
    const newSolo = !solo;
    console.log(`🎧 Solo cambiato per traccia ${trackIndex}: ${newSolo}`);
    setSolo(newSolo);

    if (song && song.tracks[trackIndex]) {
      song.tracks[trackIndex].solo = newSolo;
      console.log(`🔍 Chiamata a setTrackVolumesDependingOnMuteSoloStatus()`);
      song.setTrackVolumesDependingOnMuteSoloStatus();
    } else {
      console.warn(`⚠️ song.tracks[${trackIndex}] non esiste!`);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        backgroundColor: "#f5f5f5",
        padding: "5px",
        borderRadius: 2,
        width: "380px"
      }}
    >
      {/* Nome della traccia */}
      <Typography variant="body1" sx={{ flex: "1", fontWeight: "bold", width: "180px" }}>
        {track.name}
      </Typography>

      {/* Controllo del volume */}
      <Box sx={{ display: "flex", alignItems: "center", flex: "2" }}>
        <Tooltip title="Volume">
          <VolumeUpIcon />
        </Tooltip>
        <Slider
          value={volume}
          onChange={(e, newValue) => handleVolumeChange(newValue)}
          step={0.01}
          min={0}
          max={1}
          sx={{ marginLeft: 2, marginRight: 2 }}
        />
      </Box>

      {/* Pulsante Mute */}
      <Tooltip title={muted ? "Unmute" : "Mute"}>
        <IconButton onClick={toggleMute} color={muted ? "error" : "default"}>
          {muted ? <VolumeOffIcon /> : <VolumeUpIcon />}
        </IconButton>
      </Tooltip>

      {/* Pulsante Solo */}
      <Tooltip title={solo ? "Solo Off" : "Solo"}>
        <IconButton onClick={toggleSolo} color={solo ? "primary" : "default"}>
          {solo ? <HeadsetIcon /> : <HeadsetOffIcon />}
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default TrackControls;
