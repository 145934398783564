
import Track from './Track'; // Assumendo che Track sia un altro modulo

class Song {
    constructor(songName, context) {
        // the web audio context
        this.audioContext = context;
        // name of the song
        this.name = songName;
        // url of this song
        this.url = "track/" + songName;
        // list of tracks in that song
        this.tracks = [];
        // master volume of this song

        this.elapsedTimeSinceStart = 0;

        // song is paused ?
        this.paused = true;

        // song is muted ?
        this.muted = false;

        // Recording mix mode ?
        this.recording = false;

        // loop we start again at the beginning of the loop
        this.loopMode = false;
        this.loopStartTime = 0;
        this.loopEndTime = 0;

        // record mix ?
        this.recordMixMode = false;

        // list of decoded audio buffers
        this.decodedAudioBuffers = [];

        // The web audio graph nodes
        // Master volume
        this.masterVolumeNode = this.audioContext.createGain();
        this.trackVolumeNodes = [];
        this.analyserNode = this.audioContext.createAnalyser();
        // For saving the mix to a .wav file, it's better to build this node only once and reuse it.
        
        this.recIndex = 0; // for generating name of exported mix

        // Origin of the web audio graph, useful for start/stop/pause
        this.sampleNodes = [];
    }

    addTrack(instrument) {
        this.tracks.push(new Track(this.name, instrument));
    }

    buildGraph() {
        if (this.sampleNodes.length > 0) {
            console.warn("Graph already built, skipping.");
            return;
        }
    
        this.trackVolumeNodes = []; // ✅ Assicura che sia vuoto prima di riempirlo
        const sources = [];
    
        for (let i = 0; i < this.decodedAudioBuffers.length; i++) {
            const sample = this.decodedAudioBuffers[i];
    
            const source = this.audioContext.createBufferSource();
            source.buffer = sample;
    
            const volumeNode = this.audioContext.createGain();
            volumeNode.gain.value = this.tracks[i]?.muted ? 0 : this.tracks[i]?.volume;
    
            source.connect(volumeNode);
            volumeNode.connect(this.masterVolumeNode);
    
            this.trackVolumeNodes.push(volumeNode); // ✅ Aggiunto alla lista
    
            sources.push(source);
        }
    
        this.masterVolumeNode.connect(this.analyserNode);
        this.analyserNode.connect(this.audioContext.destination);
    
        this.sampleNodes = sources;
        console.log(`✅ trackVolumeNodes inizializzato con ${this.trackVolumeNodes.length} elementi.`);
    }
    
    
    rebuildGraph() {
        // Svuota i nodi esistenti
        this.sampleNodes.forEach((node) => {
            try {
                node.stop();
            } catch (error) {
                console.warn("Error stopping sample node:", error);
            }
        });
    
        this.sampleNodes = [];
        this.buildGraph(); // Ricostruisci la catena audio
    }
    play(startTime = null) {
        if (!this.paused) {
            console.warn("Cannot play: song is already playing.");
            return;
        }
    
        this.rebuildGraph();
        this.setTrackVolumesDependingOnMuteSoloStatus();
    
        const duration = this.getDuration();
        if (!duration || duration <= 0) {
            console.error("❌ Durata non valida. Non posso riprodurre il brano.");
            return;
        }
    
        // ✅ Assicuriamoci di avviare dal punto corretto
        if (this.loopMode && this.loopStartTime > 0) {
            startTime = this.loopStartTime;
        } else if (startTime === null) {
            startTime = this.elapsedTimeSinceStart;
        }
    
        console.log(`▶️ Riproduzione dal secondo: ${startTime.toFixed(2)} (Loop attivo: ${this.loopMode ? 'SI' : 'NO'})`);
    
        this.sampleNodes.forEach((s, index) => {
            try {
                s.start(0, startTime);
            } catch (error) {
                console.error(`Errore avviando la traccia ${index}:`, error);
            }
        });
    
        this.startTime = this.audioContext.currentTime - startTime;
        this.elapsedTimeSinceStart = startTime;
        this.paused = false;
    }
    
    
    
    
    
    
    pause() {
        if (!this.paused) {
            // ✅ Salviamo il tempo effettivo SOLO una volta
            this.elapsedTimeSinceStart = this.audioContext.currentTime - this.startTime;
    
            // ✅ Stoppiamo tutte le tracce
            this.sampleNodes.forEach((s, index) => {
                try {
                    s.stop();
                } catch (error) {
                    console.warn(`Error stopping sample node during pause for track ${index}:`, error);
                }
            });
    
            this.sampleNodes = [];
            this.paused = true;
    
            if (this.progressInterval) {
                clearInterval(this.progressInterval);
                this.progressInterval = null;
            }
    
            console.log(`PAUSA: Salvato elapsedTimeSinceStart = ${this.elapsedTimeSinceStart.toFixed(2)}s`);
        }
    }
    
    
    
    
    
    
    
    stop() {
        if (this.sampleNodes.length === 0) {
            console.warn("No nodes to stop.");
            return;
        }
    
        this.sampleNodes.forEach((s) => {
            try {
                s.stop(0);
            } catch (error) {
                console.warn("Error stopping sample node:", error);
            }
        });
    
        this.sampleNodes = []; // Svuota i nodi
        this.elapsedTimeSinceStart = 0; // Resetta il tempo
        this.paused = true;
    
        if (this.recordMixMode) {
            this.toggleRecording();
        }
    }
    


    setVolume(value) {
        this.volume = value;
        this.masterVolumeNode.gain.value = value;
    }

    setVolumeOfTrack(value, trackNumber) {
        if (this.trackVolumeNodes[trackNumber]) {
            console.log(`🎚️ Modifica volume traccia ${trackNumber}: ${value}`);
            console.log(`🔍 trackVolumeNodes[${trackNumber}] prima:`, this.trackVolumeNodes[trackNumber].gain.value);
    
            this.trackVolumeNodes[trackNumber].gain.value = value;
            this.tracks[trackNumber].volume = value;
    
            console.log(`✅ trackVolumeNodes[${trackNumber}] dopo:`, this.trackVolumeNodes[trackNumber].gain.value);
        } else {
            console.warn(`⚠️ trackVolumeNodes[${trackNumber}] non esiste!`);
        }
    }
    

    getUrlsOfTracks() {
        return this.tracks.map((track) => track.url);
    }

    getDuration() {
         
        if (!this.decodedAudioBuffers || this.decodedAudioBuffers.length === 0) {
            console.warn("⚠️ Nessun audio buffer disponibile.");
            return 0;
        }
        
        return this.decodedAudioBuffers[0]?.duration || 0;
    }
    

    getNbTracks() {
        return this.tracks.length;
    }

    setDecodedAudioBuffers(buffers) {
        this.decodedAudioBuffers = buffers;

        for (let i = 0; i < this.tracks.length; i++) {
            this.tracks[i].decodedBuffer = this.decodedAudioBuffers[i];
        }
    }

    toggleLoopMode(start, end) {
        if (start < end) {
            this.loopMode = true;
            this.loopStartTime = start;
            this.loopEndTime = end;
            console.log(`Loop Mode ON: ${this.loopStartTime} → ${this.loopEndTime}`);
        } else {
            this.loopMode = false;
            console.log("Loop Mode OFF");
        }
    }
    

    toggleMute() {
        this.muted = !this.muted;
        this.masterVolumeNode.gain.value = this.muted ? 0 : this.volume;
    }

    setTrackVolumesDependingOnMuteSoloStatus() {
        const hasSolo = this.tracks.some((track) => track?.solo);
    
        this.trackVolumeNodes.forEach((node, i) => {
            // Verifica che la traccia esista
            const track = this.tracks[i];
            if (!track) {
                console.warn(`Track at index ${i} is undefined.`);
                node.gain.value = 0; // Imposta il volume a 0 per sicurezza
                return;
            }
    
            // Se c'è almeno una traccia in solo, gestisci di conseguenza
            if (hasSolo) {
                node.gain.value = track.solo ? track.volume : 0;
            } else {
                node.gain.value = track.muted ? 0 : track.volume;
            }
        });
    }
    


    setGainNode(gainNode) {
        this.masterVolumeNode = gainNode;
        if (isFinite(this.volume)) {
            this.masterVolumeNode.gain.value = this.volume;
          } else {
            console.warn('Invalid volume value:', this.volume);
            this.masterVolumeNode.gain.value = 1;  // Imposta il volume a un valore predefinito valido
          }        // Ricollega tutti i nodi di volume dei track al master volume node
        this.trackVolumeNodes.forEach((volumeNode) => {
            volumeNode.connect(this.masterVolumeNode);
        });
        // Collega il master volume node all'analyzer e quindi al destination
        if (this.masterVolumeNode.context !== this.analyserNode.context) {
            console.error("I nodi appartengono a contesti audio differenti!");
          } else {
            this.masterVolumeNode.connect(this.analyserNode);
          }
        this.analyserNode.connect(this.audioContext.destination);
    }


}

export default Song;
