import React, { useState, useEffect, useRef } from "react";
import { TextField, Button, Container, Typography, Paper, MenuItem, Select, InputLabel, FormControl, Avatar, Box, IconButton, Snackbar, Alert } from "@mui/material";
import PhotoCamera from "@mui/icons-material/PhotoCamera";

const Profile = () => {
    const [profile, setProfile] = useState({
        profilePicture: "",
        instrument_id: "",
        years_of_experience: "",
        preferred_genres_id: "",
        learning_goals_id: "",
        weekly_practice_hours: "",
    });

    const [instruments, setInstruments] = useState([]);
    const [genres, setGenres] = useState([]);
    const [learningGoals, setLearningGoals] = useState([]);
    const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" });

    const fileInputRef = useRef(null);

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const token = localStorage.getItem("jwtToken");
                const response = await fetch(`${process.env.REACT_APP_API_URL}/profile/me`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
        
                if (!response.ok) throw new Error("Failed to fetch profile");
        
                const data = await response.json();
                setProfile({
                    profilePicture: data.profile_picture || "",
                    instrument_id: data.instrument_id || "",
                    years_of_experience: data.years_of_experience || "",
                    preferred_genres_id: data.preferred_genres_id || "",
                    learning_goals_id: data.learning_goal_id || "", // Modificato
                    weekly_practice_hours: data.weekly_practice_hours || "",
                });
            } catch (error) {
                console.error("Error fetching profile:", error);
            }
        };

        const fetchDropdownOptions = async () => {
            try {
                const token = localStorage.getItem("jwtToken");

                const [instrumentsRes, genresRes, goalsRes] = await Promise.all([
                    fetch(`${process.env.REACT_APP_API_URL}/profile/instruments`, { headers: { Authorization: `Bearer ${token}` } }),
                    fetch(`${process.env.REACT_APP_API_URL}/profile/genres`, { headers: { Authorization: `Bearer ${token}` } }),
                    fetch(`${process.env.REACT_APP_API_URL}/profile/learning-goals`, { headers: { Authorization: `Bearer ${token}` } }),
                ]);

                if (!instrumentsRes.ok || !genresRes.ok || !goalsRes.ok) throw new Error("Error fetching dropdown data");

                const [instrumentsData, genresData, goalsData] = await Promise.all([
                    instrumentsRes.json(),
                    genresRes.json(),
                    goalsRes.json(),
                ]);

                setInstruments(instrumentsData);
                setGenres(genresData);
                setLearningGoals(goalsData);
            } catch (error) {
                console.error("Error fetching dropdown options:", error);
            }
        };

        fetchProfile();
        fetchDropdownOptions();
    }, []);

    const handleChange = (e) => {
        setProfile({ ...profile, [e.target.name]: e.target.value });
    };

    const handleProfilePictureChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                setProfile({ ...profile, profilePicture: event.target.result });
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const token = localStorage.getItem("jwtToken");
    
            const response = await fetch(`${process.env.REACT_APP_API_URL}/profile/me`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                    profilePicture: profile.profilePicture,
                    instrument_id: profile.instrument_id,
                    years_of_experience: profile.years_of_experience,
                    preferred_genres_id: profile.preferred_genres_id,
                    weekly_practice_hours: profile.weekly_practice_hours,
                }),
            });
    
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || "Failed to update profile.");
            }
    
            // Aggiorna la tabella UserLearningGoals
            if (profile.learning_goals_id) {
                await fetch(`${process.env.REACT_APP_API_URL}/profile/update-learning-goal`, {
                    method: "POST", // O PUT, a seconda della tua API
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        learning_goal_id: profile.learning_goals_id,
                    }),
                });
            }
    
            setSnackbar({ open: true, message: "Profile updated successfully!", severity: "success" });
        } catch (error) {
            console.error("Error updating profile:", error);
            setSnackbar({ open: true, message: "Failed to update profile!", severity: "error" });
        }
    };

    return (
        <Container component={Paper} sx={{ padding: "20px", marginTop: "20px" }}>
            <Typography variant="h4" gutterBottom>
                My Profile
            </Typography>

            {/* Profile Picture Upload */}
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "10px", marginBottom: "20px" }}>
                <Box
                    sx={{
                        position: "relative",
                        width: 100,
                        height: 100,
                        borderRadius: "50%",
                        overflow: "hidden",
                        cursor: "pointer",
                        "&:hover .overlay": { opacity: 1 },
                    }}
                    onClick={() => fileInputRef.current.click()}
                >
                    <Avatar
                        src={profile.profilePicture}
                        sx={{ width: "100%", height: "100%" }}
                    />
                    <Box
                        className="overlay"
                        sx={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            opacity: 0,
                            transition: "opacity 0.3s",
                        }}
                    >
                        <PhotoCamera sx={{ color: "white", fontSize: 40 }} />
                    </Box>
                </Box>
                <input
                    type="file"
                    accept="image/*"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleProfilePictureChange}
                />
            </Box>

            {/* Form */}
            <form onSubmit={handleSubmit}>
                <FormControl fullWidth margin="normal">
                    <InputLabel>Instrument</InputLabel>
                    <Select name="instrument_id" value={profile.instrument_id} onChange={handleChange}>
                        {instruments.map((instrument) => (
                            <MenuItem key={instrument.id} value={instrument.id}>
                                {instrument.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <TextField
                    fullWidth
                    margin="normal"
                    label="Years of Experience"
                    type="number"
                    name="years_of_experience"
                    value={profile.years_of_experience}
                    onChange={handleChange}
                />

                <FormControl fullWidth margin="normal">
                    <InputLabel>Preferred Genres</InputLabel>
                    <Select name="preferred_genres_id" value={profile.preferred_genres_id} onChange={handleChange}>
                        {genres.map((genre) => (
                            <MenuItem key={genre.id} value={genre.id}>
                                {genre.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl fullWidth margin="normal">
                    <InputLabel>Learning Goals</InputLabel>
                    <Select name="learning_goals_id" value={profile.learning_goals_id} onChange={handleChange}>
                        {learningGoals.map((goal) => (
                            <MenuItem key={goal.id} value={goal.id}>
                                {goal.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <TextField
                    fullWidth
                    margin="normal"
                    label="Weekly Practice Hours"
                    type="number"
                    name="weekly_practice_hours"
                    value={profile.weekly_practice_hours}
                    onChange={handleChange}
                />

                <Button type="submit" variant="contained" color="primary" sx={{ marginTop: "20px" }}>
                    Update Profile
                </Button>
            </form>

            {/* Snackbar for notifications */}
            <Snackbar open={snackbar.open} autoHideDuration={3000} onClose={() => setSnackbar({ ...snackbar, open: false })}>
                <Alert severity={snackbar.severity} sx={{ width: "100%" }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default Profile;
