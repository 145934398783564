import React, { useState, useEffect } from 'react';
import { Container, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const ExerciseHistory = () => {
    const [history, setHistory] = useState([]);

    useEffect(() => {
        const fetchHistory = async () => {
            try {
                const token = localStorage.getItem('jwtToken');
                const response = await fetch(`${process.env.REACT_APP_API_URL}/profile/progress`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                const data = await response.json();
                setHistory(data);
            } catch (error) {
                console.error("Errore nel recupero dello storico:", error);
            }
        };

        fetchHistory();
    }, []);

    return (
        <Container component={Paper} style={{ padding: '20px', marginTop: '20px' }}>
            <Typography variant="h4" gutterBottom>Storico Esercizi</Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Data</TableCell>
                            <TableCell>Nome Esercizio</TableCell>
                            <TableCell>Tempo Impiegato (sec)</TableCell>
                            <TableCell>Difficoltà</TableCell>
                            <TableCell>Note</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {history.map((exercise) => (
                            <TableRow key={exercise.Id}>
                                <TableCell>{new Date(exercise.DataEsecuzione).toLocaleString()}</TableCell>
                                <TableCell>{exercise.esercizio}</TableCell>
                                <TableCell>{exercise.tempo_impiegato}</TableCell>
                                <TableCell>{exercise.difficolta_percepita}</TableCell>
                                <TableCell>{exercise.note_utente}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
};

export default ExerciseHistory;
