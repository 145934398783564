import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import Features from './components/Features';
import Pricing from './components/Pricing';
import Footer from './components/Footer';
import RegisterModal from './components/RegisterModal';
import LoginModal from './components/LoginModal';
import Dashboard from './components/Dashboard';
import FileManager from './components/FileManager/FileManager';
import ChordsComponent from "./components/ChordsComponent";
import FamiglieLivelli from "./components/FamiglieLivelli";
import Profile from "./components/Profile";
import ExerciseHistory from "./components/ExerciseHistory";
import * as authApi from './authApi';
import ZipUploadPanel from './components/FileManager/ZipUploadPanel';

function PrivateRoute({ children, isAuthenticated, isLoading }) {
    if (isLoading) {
        return <div>Loading...</div>;
    }
    return isAuthenticated ? children : <Navigate to="/" />;
}

function App() {
    const [user, setUser] = useState(null);
    const [isLoadingUser, setIsLoadingUser] = useState(true);
    const [isRegisterModalOpen, setRegisterModalOpen] = useState(false);
    const [isLoginModalOpen, setLoginModalOpen] = useState(false);
    const [loginError, setLoginError] = useState('');  // For displaying errors
    const [registerError, setRegisterError] = useState(''); // For displaying errors
    // REMOVED: suggestedExercises state - no longer needed in App.js

    useEffect(() => {
        const loadUser = async () => {
            const token = localStorage.getItem('jwtToken');
            const storedUser = JSON.parse(localStorage.getItem('user') || 'null');

            if (token && storedUser) {
                try {
                    const decodedToken = JSON.parse(atob(token.split('.')[1]));
                    if (decodedToken.exp * 1000 > Date.now()) {
                        try {
                            const verifiedUser = await authApi.verifyToken(token);
                            setUser(verifiedUser);
                            localStorage.setItem('user', JSON.stringify(verifiedUser));

                            // REMOVED: Fetching exercises here.  Dashboard will handle it.

                        } catch (error) {
                            console.error("Token verification failed:", error);
                            localStorage.removeItem('jwtToken');
                            localStorage.removeItem('user');
                            setUser(null);
                        }
                    } else {
                        localStorage.removeItem('jwtToken');
                        localStorage.removeItem('user');
                        setUser(null);
                    }
                } catch (err) {
                    console.error('Error decoding or verifying token:', err);
                    localStorage.removeItem('jwtToken');
                    localStorage.removeItem('user');
                    setUser(null);
                }
            }
            setIsLoadingUser(false);
        };

        loadUser();
    }, []);

      const handleRegister = async (userData) => {
        try {
            setRegisterError(''); // Clear previous errors
            const name = `${userData.firstName} ${userData.lastName}`;
            const requestData = {
                email: userData.email,
                password: userData.password,
                name: name,
            };

            const data = await authApi.register(requestData);
            localStorage.setItem('jwtToken', data.token);
            localStorage.setItem('user', JSON.stringify(data.user));
            setUser(data.user);
            setRegisterModalOpen(false);

        } catch (err) {
            console.error('Error during registration:', err.message);
            setRegisterError(err.message); // Set the error state
        }
    };

    const handleLogin = async ({ email, password }) => {
        try {
            setLoginError(''); // Clear previous error
            const data = await authApi.login({ email, password });
            localStorage.setItem('jwtToken', data.token);
            localStorage.setItem('user', JSON.stringify(data.user));
            setUser(data.user);
            // REMOVED: Fetching exercises here
            setLoginModalOpen(false);
        } catch (error) {
            console.error("Login error:", error);
            setLoginError(error.message);  // Set the error state
        }
    };

    const handleLogout = () => { //no need to be async
        localStorage.removeItem('jwtToken');
        localStorage.removeItem('user');
        setUser(null);
    };

     const handleGoogleLogin = async (credentialResponse) => {
        try {
          setLoginError(''); // Clear previous error
            // Use API function for Google Login
            const data = await authApi.googleLogin(credentialResponse.credential);
            localStorage.setItem('jwtToken', data.token);
            localStorage.setItem('user', JSON.stringify(data.user));
            setUser(data.user);
			// REMOVED: Fetching exercises here
            setLoginModalOpen(false);
        } catch (err) {
            console.error('Error during Google login:', err.message);
            setLoginError(err.message); //Set the error message
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Router>
                <Header user={user} onLogout={handleLogout} onLoginModalOpen={() => setLoginModalOpen(true)}  />
                <Routes>
                     <Route path="/dashboard" element={<PrivateRoute isAuthenticated={!!user} isLoading={isLoadingUser}><Dashboard/></PrivateRoute>} />
                    <Route path="/profile" element={<PrivateRoute isAuthenticated={!!user} isLoading={isLoadingUser}><Profile /></PrivateRoute>} />
                    <Route path="/chords" element={<PrivateRoute isAuthenticated={!!user} isLoading={isLoadingUser}><ChordsComponent /></PrivateRoute>} />
                    <Route path="/history" element={<PrivateRoute isAuthenticated={!!user} isLoading={isLoadingUser}><ExerciseHistory /></PrivateRoute>} />

                    {user?.role === 'admin' && (
                        <>
                            <Route path="/file-manager" element={<PrivateRoute isAuthenticated={!!user} isLoading={isLoadingUser}><FileManager /></PrivateRoute>} />
                            <Route path="/famiglielivelli" element={<PrivateRoute isAuthenticated={!!user} isLoading={isLoadingUser}><FamiglieLivelli /></PrivateRoute>} />
                            <Route path="/upload-zip" element={<PrivateRoute isAuthenticated={!!user} isLoading={isLoadingUser}><ZipUploadPanel /></PrivateRoute>} />

                        </>
                    )}

                    <Route
                        path="/"
                        element={
                            isLoadingUser ? (
                                <div>Loading...</div>
                            ) : user ? (
                                <Navigate to="/dashboard" />
                            ) : (
                                <>
                                    <HeroSection />
                                    <Features />
                                    <Pricing />
                                    <Footer />
                                     <RegisterModal
                                      open={isRegisterModalOpen}
                                      handleClose={() => setRegisterModalOpen(false)}
                                      onSubmit={handleRegister}
                                      error={registerError}
                                    />
                                    <LoginModal
                                        open={isLoginModalOpen}
                                        handleClose={() => setLoginModalOpen(false)}
                                        onSubmit={handleLogin}
                                        onGoogleLogin={handleGoogleLogin}
                                        error={loginError} // Pass the error prop
                                    />
                                </>
                            )
                        }
                    />
                </Routes>
            </Router>
        </ThemeProvider>
    );
}

export default App;