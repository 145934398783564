import React, { useState, useEffect } from 'react';
import * as exerciseApi from '../exerciseApi';
import {
    Card, CardContent, Typography, Grid, Rating, Box, Button, LinearProgress, Tooltip, Icon,
    Dialog, DialogTitle, DialogContent, DialogActions, RadioGroup, FormControlLabel, Radio,
    TextField, FormControl, FormLabel, Paper, Snackbar, Alert
} from '@mui/material';
import { styled } from '@mui/material/styles';

const FeedbackModal = ({ open, onClose, exerciseId }) => {
    const [difficulty, setDifficulty] = useState('');
    const [satisfaction, setSatisfaction] = useState(0);
    const [accuracy, setAccuracy] = useState('');
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
    const [loading, setLoading] = useState(false);

    // Reset form fields when modal opens
    useEffect(() => {
        if (open) {
            resetForm();
        }
    }, [open]);

    const resetForm = () => {
        setDifficulty('');
        setSatisfaction(0);
        setAccuracy('');
    };

    const handleSubmit = async () => {
        if (!difficulty) {
            setSnackbar({ open: true, message: 'Please select the perceived difficulty.', severity: 'warning' });
            return;
        }

        setLoading(true);

        try {
            const feedbackData = {
                exercise_id: exerciseId,
                time_spent: 10, // TODO: Add actual time spent
                perceived_difficulty: difficulty,
                satisfaction: satisfaction,
                perceived_accuracy: accuracy ? parseInt(accuracy) : null,
            };

            await exerciseApi.postFeedback(exerciseId, feedbackData);

            setSnackbar({ open: true, message: 'Feedback submitted successfully!', severity: 'success' });
            onClose();
        } catch (error) {
            setSnackbar({ open: true, message: error.message || 'Failed to submit feedback.', severity: 'error' });
        } finally {
            setLoading(false);
        }
    }

    const handleCloseSnackbar = () => {
        setSnackbar(prev => ({ ...prev, open: false }));
    };

    const StyledRating = styled(Rating)({
        '& .MuiRating-iconFilled': {
            color: '#FF6F61',
        },
        '& .MuiRating-iconEmpty': {
            color: '#bdbdbd',
        },
    });

    return (
        <>
            <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title" fullWidth maxWidth="xs">
                <DialogTitle id="form-dialog-title">Exercise Feedback</DialogTitle>
                <DialogContent>
                    <Paper elevation={3} sx={{ padding: 2, marginBottom: 2 }}>
                        <Typography variant="body2" sx={{ mb: 1, color: '#555' }}>
                            Please provide your feedback about this exercise.
                        </Typography>

                        {/* Perceived Difficulty */}
                        <FormControl component="fieldset" fullWidth margin="dense">
                            <FormLabel component="legend">Perceived Difficulty</FormLabel>
                            <RadioGroup
                                aria-label="difficulty"
                                name="difficulty"
                                value={difficulty}
                                onChange={(e) => setDifficulty(e.target.value)}
                            >
                                <FormControlLabel value="1" control={<Radio />} label="Easy" />
                                <FormControlLabel value="2" control={<Radio />} label="Medium" />
                                <FormControlLabel value="3" control={<Radio />} label="Hard" />
                            </RadioGroup>
                        </FormControl>

                        {/* Execution Accuracy */}
                        <FormControl component="fieldset" fullWidth margin="dense">
                            <FormLabel component="legend">Execution Accuracy</FormLabel>
                            <StyledRating
                                name="accuracy"
                                value={accuracy}
                                onChange={(event, newValue) => setAccuracy(newValue)}
                                max={5}
                                size="large"
                            />
                        </FormControl>

                        {/* Satisfaction */}
                        <FormControl component="fieldset" fullWidth margin="dense">
                            <FormLabel component="legend">Satisfaction</FormLabel>
                            <StyledRating
                                name="satisfaction"
                                value={satisfaction}
                                onChange={(event, newValue) => setSatisfaction(newValue)}
                                max={5}
                                size="large"
                            />
                        </FormControl>
                    </Paper>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} color="primary" variant="contained" disabled={loading}>
                        {loading ? 'Submitting...' : 'Submit'}
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </>
    );
};

export default FeedbackModal;